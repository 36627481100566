import { useEffect, useState } from 'react';
import {
  Card,
  CardTitle,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';

import PropTypes from 'prop-types';
import moment from 'moment';
import { getWindowSize } from '../../functions/common';

require('moment/locale/en-gb');

const TimelineControl = (props) => {
  const [height, setHeight] = useState({ height: props.height });

  useEffect(() => {
    if (props.height) {
      setHeight({ height: props.height });
    } else {
      setHeight(getWindowSize().width > 768 ? { height: '465px' } : { height: '350px' });
    }
  }, []);

  return (
    <Card>
      <CardTitle
        tag="h4"
        style={{ paddingTop: '5px', paddingBottom: '5px' }}
        className="bg-white text-black text-center"
      >
        <span>Activity Log</span>
      </CardTitle>
      <SimpleBar style={height}>
        <ListGroup className="bordered-list rounded-0">
          {props.data.map((icomment, index) => {
            return (
              //eslint-disable-next-line react/no-array-index-key
              <div key={`${icomment}_${index}`}>
                {icomment.PropertyName.split('/?').map((ic, index2) => {
                  if (ic === 'Status' || ic === 'SubStatus' || ic === 'Addendum') {
                    return (
                      <ListGroupItem
                        action
                        href="#"
                        tag="a"
                        key={`${icomment.CreatedBy}_${icomment.CreatedAt}`}
                        className="bg-white d-flex py-3 px-4 w-100 border-bottom-0 border-top-0 border-end-0"
                      >
                        <div className="w-100">
                          {/* eslint-disable-next-line react/no-array-index-key */}
                          <div key={`${ic}_${index2}`}>
                            <ListGroupItemHeading className="fw-bold mb-0">
                              {icomment.CreatedBy}
                            </ListGroupItemHeading>
                            <div key={`${ic}`}>
                              <ListGroupItemText className="mt-1 mb-2 text-muted fs-6">
                                {icomment.NewValue.split('/?')[index2]}
                              </ListGroupItemText>
                              <div className="d-flex">
                                <small className="ms-auto">
                                  {moment(icomment.CreatedAt).format('MMMM DD, YYYY h:mmA')}
                                </small>
                              </div>
                            </div>
                            <hr className="p-0 m-0" />
                          </div>
                        </div>
                      </ListGroupItem>
                    );
                  }
                  return '';
                })}
              </div>
            );
          })}
        </ListGroup>
      </SimpleBar>
    </Card>
  );
};

TimelineControl.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
};

export default TimelineControl;
