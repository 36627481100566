import React, { useEffect, useState, useRef } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  FormText,
  Collapse,
  Badge,
  Container,
} from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Formik, Field, Form as FormikForm } from 'formik';
import 'react-datetime/css/react-datetime.css';
import * as Icon from 'react-feather';

import TooltipItem from '../../widgets/Tooltip';
import BreadCrumbs from '../../../layouts/breadcrumbs/BreadCrumbs';
import { getAsync, putAsync } from '../../../functions/my-api';
import {
  ProcessingModal,
  ErrorModal,
  VeryLargeModal,
  ConfirmationModal,
  SmallModal,
  WarningModal,
  RequiredFieldsWarningModal,
  GenericMessageModal,
} from '../../modals/CustomModals';
import IswForm from '../shared/IswForm';
import PswForm from '../shared/PswForm';
import FinalPswForm from './FinalPswForm';
import EngineeringUploadForm from '../shared/EngineeringUploadForm';
import * as fileHelper from '../../../functions/my-file';
import TimelineControl from '../../widgets/TimelineControl';
import ActiveDirectoryAutoSuggest from '../../widgets/ActiveDirectoryAutoSuggest';
import ContractorAutoSuggest from '../../widgets/ContractorAutoSuggest';
import QuoteUploadForm from '../shared/QuoteUploadForm';
import SimProQuoteLookUp from '../../widgets/SimProQuoteLookup';
import { generateRandomString, getElementHeight } from '../../../functions/common';
import TenderDetails from '../../widgets/TenderDetails';
import SiteContactDetails from '../../widgets/SiteContactDetails';
import Milestone from '../../widgets/Milestone';
import ContactDetails from '../../widgets/ContactDetails';
import TenderDocumentation from '../../widgets/TenderDocumentation';
import Addendum from '../../widgets/Addendum';

require('moment/locale/en-gb');

const TenderForm = () => {
  const styles = {
    cell: {
      border: '1px solid #DEE2E6',
      padding: '8px 12px',
      textAlign: 'left',
    },
    row: {
      border: '1px solid #DEE2E6',
      padding: '.25em',
    },
  };

  // eslint-disable-next-line no-unused-vars
  const currentLoggedInUser = JSON.parse(localStorage.JETChargeCurrentUser).name;

  const iswModalRef = useRef();
  const pswModalRef = useRef();
  const finalPswModalRef = useRef();
  const confirmedPswModalRef = useRef();
  const confirmedPswOldModalRef = useRef();
  const iswFormRef = useRef();
  const pswFormRef = useRef();
  const finalPswFormRef = useRef();
  const confirmedPswFormRef = useRef();
  const processingModalRef = useRef();
  const successModalRef = useRef();
  const errorModalRef = useRef();
  const rejectModalRef = useRef();
  const engineeringUploadFormRef = useRef();
  const documentSelectModalRef = useRef();
  const quoteUploadFormRef = useRef();
  const nothingToDownloadModalRef = useRef();
  const peerRejectModalRef = useRef();
  const requiredFieldsWarningModalRef = useRef();
  const formikRef = useRef();
  const archiveModalRef = useRef();
  const tenderDetailsRef = useRef();
  const tenderDocumentationRef = useRef();
  const siteContactDetailsRef = useRef();

  const [pageLoad, setPageLoad] = useState(generateRandomString(6));
  const [iswValues, setIswValues] = useState({ q1: '', q2: '', q3: '', Status: '', SubStatus: '' });
  const [pswValues, setPswValues] = useState({ Status: '', SubStatus: '' });
  const [finalPswValues, setFinalPswValues] = useState({});
  const [siteVisitHistory, setSiteVisitHistory] = useState([]);
  const [engineeringFiles, setEngineeringFiles] = useState([]);
  const [assignedTech, setAssignedTech] = useState('');
  const [peerReviewer, setPeerReviewer] = useState('');
  const [assignedProjectAdmin, setAssignedProjectAdmin] = useState('');
  const [assignedContractor, setAssignedContractor] = useState('');
  const [renderAgain, setRenderAgain] = useState(false);
  const [readOnly, setReadOnly] = useState(window.location.pathname.includes('/view'));
  const [pswCollapse, setPswCollapse] = useState(true);
  const [finalPswCollapse, setFinalPswCollapse] = useState(true);
  const [quoteValues, setQuoteValues] = useState({});
  const [confirmedPswValues, setConfirmedPswValues] = useState({});
  const [engineeringQuestionsAnswered, setEngineeringQuestionsAnswered] = useState(false);
  const [simProQuote, setSimProQuote] = useState('');
  const [successModalBody, setSuccessModalBody] = useState('');
  const [tenderDetails, setTenderDetails] = useState({});
  const [mainSiteContact, setMainSiteContact] = useState({});
  const [contacts, setContacts] = useState([]);
  const [tenderDocumentationFiles, setTenderDocumentationFiles] = useState([]);

  const SITE_ID = window.location.pathname.split('/')[3];
  const ISW_URL = `${process.env.REACT_APP_TSA_URL}/isw`;
  const PSW_URL = `${process.env.REACT_APP_TSA_URL}/psw`;
  const FINAL_PSW_URL = `${process.env.REACT_APP_TSA_URL}/finalpsw`;
  const CONFIRMED_PSW_URL = `${process.env.REACT_APP_TSA_URL}/confirmed/psw`;
  const QUOTE_URL = `${process.env.REACT_APP_TSA_URL}/isw/${SITE_ID}/quote`;
  const ENG_URL = `${process.env.REACT_APP_TSA_URL}/eng`;
  const PSW_FILE_UPLOAD_URL = `${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`;
  const PSW_FILE_DOWNLOAD_URL = `${process.env.REACT_APP_TSA_URL}/psw/${SITE_ID}/file`;
  const CONFIRMED_PSW_UPLOAD_URL = `${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`;
  const CONFIRMED_PSW_DOWNLOAD_URL = `${process.env.REACT_APP_TSA_URL}/confirmed/psw/${SITE_ID}/file`;
  const ENG_FILE_UPLOAD_URL = `${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`;
  const ENG_FILE_DOWNLOAD_URL = `${process.env.REACT_APP_TSA_URL}/eng/${SITE_ID}/file`;
  const QUOTE_FILE_UPLOAD_URL = `${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`;
  const QUOTE_FILE_DOWNLOAD_URL = `${process.env.REACT_APP_TSA_URL}/isw/${SITE_ID}/quote/file`;
  const TSA_ADMINS = process.env.REACT_APP_TSA_ADMINS.split(';');

  const QUOTE_MARKUP_REVIEW_DOC_URL =
    'https://drive.google.com/uc?export=download&id=1l4vH3XTouVQYDPEjd_5PnYNKHhPumbAt';

  const getTechSiteVisit = async (params) => {
    if (params?.showProcessingModal) processingModalRef.current.toggleModal();

    const response1 = await getAsync(`${ISW_URL}/${SITE_ID}`);
    const response3 = await getAsync(`${ISW_URL}/${SITE_ID}/history`);
    const response4 = await getAsync(`${ISW_URL}/${SITE_ID}/sitevisit`);
    const response6 = await getAsync(`${FINAL_PSW_URL}/${SITE_ID}`);
    const response7 = await getAsync(`${PSW_URL}/${SITE_ID}`);
    const response8 = await getAsync(`${QUOTE_URL}`);
    const response10 = await getAsync(`${CONFIRMED_PSW_URL}/${SITE_ID}?pdfOnly=true`);

    if (
      response1.status !== 200 ||
      response3.status !== 200 ||
      response4.status !== 200 ||
      response6.status !== 200 ||
      response7.status !== 200 ||
      response8.status !== 200 ||
      response10.status !== 200
    ) {
      processingModalRef.current.toggleModal();
      errorModalRef.current.toggleModal();
    } else {
      processingModalRef.current.toggleModal();

      if (
        response1.data?.Status === 'Completed TSA' &&
        (response1.data?.SubStatus === 'Approved Estimates' ||
          response1.data?.SubStatus === 'Quote sent to Client, awaiting feedback' ||
          response1.data?.SubStatus === 'On Hold / Decision still pending' ||
          response1.data?.SubStatus === 'Client did not go ahead')
      ) {
        setReadOnly(true);
      }

      // Default checked for Preliminary Scope of Works for assigned documents to contractor
      let response4Defaults = {};
      if (
        !response4.data.Data.iswCheck &&
        !response4.data.Data.siteInfoCheck &&
        !response4.data.Data.pswCheck &&
        !response4.data.Data.engineeringCheck
      ) {
        response4Defaults = { pswCheck: true };
      }

      setAssignedTech(response1.data?.AssignedTech);
      setAssignedContractor(response1.data?.AssignedContractor);
      setAssignedProjectAdmin(response1.data?.AssignedProjectAdmin);
      setPeerReviewer(response1.data?.PeerReviewer);
      setSimProQuote(response1.data?.QuoteNumber);
      setSiteVisitHistory(response3.data);
      setIswValues({ ...response1.data, ...response4.data.Data, ...response4Defaults });
      setPswValues(response7.data);
      setFinalPswValues({ ...response6.data, DocumentNumber: response1.data.DocumentNumber });
      setQuoteValues(response8.data);
      setConfirmedPswValues(response10.data);
      setPageLoad(generateRandomString(6));
      setTenderDetails(response1.data.TenderDetails);
      setTenderDocumentationFiles(response1.data.TenderDocumentationFiles ?? []);

      const siteContacts = JSON.parse(
        response1.data.SiteContactDetails ? response1.data.SiteContactDetails : '[]',
      );
      setMainSiteContact({
        SiteContactName_1: siteContacts[0]?.SiteContactName_1 ?? '',
        SiteContactRole_1: siteContacts[0]?.SiteContactRole_1 ?? '',
        SiteMobileNumberCountry_1: siteContacts[0]?.SiteMobileNumberCountry_1 ?? '',
        SiteMobileNumber_1: siteContacts[0]?.SiteMobileNumber_1 ?? '',
        SiteEmail_1: siteContacts[0]?.SiteEmail_1 ?? '',
        SiteAddress_1: siteContacts[0]?.SiteAddress_1 ?? '',
        SiteName_1: siteContacts[0]?.SiteName_1 ?? '',
        State_1: siteContacts[0]?.State_1 ?? '',
      });

      const multicontacts = JSON.parse(response1.data.ContactDetails ?? '[]');
      setContacts(multicontacts);

      const response2 = await getAsync(`${ISW_URL}/${SITE_ID}/files`);
      const response5 = await getAsync(`${ENG_URL}/${SITE_ID}/files`);

      if (response2.status !== 200 || response5.status !== 200) {
        errorModalRef.current.toggleModal();
      }

      const engineeringData = {
        Photos: response5.data ?? [],
        Questions: response4.data.Data ?? {},
      };

      setEngineeringFiles(engineeringData);

      // check if the 3 engineering questions has been answered
      if (
        engineeringData.Questions?.engineeringDesign &&
        engineeringData.Questions?.supplyAuthorityDocumentation &&
        engineeringData.Questions?.dataLoggingOutput
      ) {
        setEngineeringQuestionsAnswered(true);
      }

      setPageLoad(generateRandomString(6));
    }
  };

  useEffect(() => {
    getTechSiteVisit({ showProcessingModal: true });
  }, []);

  useEffect(() => {
    setRenderAgain(!renderAgain);
  }, [pswValues, finalPswValues]);

  const toggleEditFinalScopeOfWorksModal = async () => {
    finalPswModalRef.current.toggleModal();
  };

  const toggleEditConfirmedPreliminaryScopeOfWorksModal = async () => {
    processingModalRef.current.toggleModal();

    const confirmedPswData = await getAsync(`${CONFIRMED_PSW_URL}/${SITE_ID}?pdfOnly=false`);
    if (confirmedPswData.status === 200) {
      setConfirmedPswValues(confirmedPswData.data);
    } else {
      errorModalRef.current.toggleModal();
    }

    if ('msbDbsPhotos' in confirmedPswValues.Data) {
      confirmedPswOldModalRef.current.toggleModal();
    } else {
      confirmedPswModalRef.current.toggleModal();
    }

    processingModalRef.current.toggleModal();
  };

  const toggleEditPreliminaryScopeOfWorksModal = async () => {
    processingModalRef.current.toggleModal();
    const response7 = await getAsync(`${PSW_URL}/${SITE_ID}`);
    processingModalRef.current.toggleModal();

    if (response7.status === 200) {
      setPswValues(response7.data);

      pswModalRef.current.toggleModal();
    } else {
      errorModalRef.current.toggleModal();
    }
  };

  const getQuestionAnswers = async () => {
    const answers = {};

    // this is 11 because we have up to q11
    for (let i = 1; i <= 11; i++) {
      const key = `q${i}`;
      const value = formikRef.current.values[`q${i}`];

      answers[key] = value;
    }

    // Add the Reject reason
    if (formikRef.current.values.SubStatus === 'Rejected') {
      answers.RejectReason = await rejectModalRef.current.getCommentBody();
    }

    // Add the Quote Revision reason
    if (
      (formikRef.current.values.Status === 'Site Assessment' ||
        formikRef.current.values.Status === 'Desktop Assessment') &&
      formikRef.current.values.SubStatus === 'Revision Requested'
    ) {
      answers.RejectReason = await quoteUploadFormRef.current.getRejectReason();
    }

    // Add the Peer Request Revision reason
    if (
      formikRef.current.values.Status === 'Cost Estimate' &&
      formikRef.current.values.SubStatus === 'Revision Requested'
    ) {
      answers.RejectReason = await peerRejectModalRef.current.getCommentBody();
    }

    return answers;
  };

  const handleComplete = async (formik) => {
    processingModalRef.current.toggleModal();

    let invalidFields = await formik.validateForm();

    if (formik.values.q8 === 'yes' || formik.values.q9 === 'yes') {
      if (!assignedContractor) {
        formik.errors.assignedContractor = 'Required';
        invalidFields.assignedContractor = 'Assigned Contractor is required';
      } else {
        delete formik.errors.assignedContractor;
        delete invalidFields.assignedContractor;
      }

      // Hack for controls that needs to be rendered again
      setRenderAgain(!renderAgain);
    }

    if (engineeringUploadFormRef.current) {
      // The Engineering fields are displayed
      const engineeringFormIsValid = await engineeringUploadFormRef?.current?.validateForm();
      invalidFields = { ...engineeringFormIsValid, ...invalidFields };
    }

    if (quoteUploadFormRef.current) {
      // The Engineering fields are displayed
      const quoteFormIsValid = await quoteUploadFormRef?.current?.validateForm();
      invalidFields = { ...quoteFormIsValid, ...invalidFields };
    }

    if (tenderDocumentationRef.current) {
      const tenderDocumentationRefIsValid = await tenderDocumentationRef?.current?.validateForm();
      invalidFields = { ...tenderDocumentationRefIsValid, ...invalidFields };
    }

    if (Object.keys(invalidFields).length !== 0) {
      const invalidFieldsArray = Object.values(invalidFields);

      processingModalRef.current.toggleModal();
      requiredFieldsWarningModalRef.current.toggleModal(invalidFieldsArray);
      return;
    }

    // Format the data
    const questionAnswers = await getQuestionAnswers();
    const engineeringData = await engineeringUploadFormRef.current?.getFormikValues();
    const quoteData = (await quoteUploadFormRef.current?.getFormikValues()) ?? {};

    // Add the tender values
    //if (formikRef.current.values.Tender) {
    const tenderValues = tenderDetailsRef.current?.getInputValues();
    const tenderDocumentation = tenderDocumentationRef.current?.getInputValues();

    const data = {};
    data.tenderProjectTitle = tenderValues.TenderProjectTitle;
    data.tenderLocation = tenderDocumentation?.TenderLocation ?? '';
    data.tenderNotes = tenderDocumentation?.TenderNotes ?? '';

    formikRef.current.values.TenderDetails = {
      ...formikRef.current.values.TenderDetails,
      ...data,
    };
    //}

    const assignedContractorDocuments = {
      siteInfoCheck: formik.values.siteInfoCheck,
      iswCheck: formik.values.iswCheck,
      pswCheck: formik.values.pswCheck,
      engineeringCheck: formik.values.engineeringCheck,
    };

    const mergedData = { ...questionAnswers, ...engineeringData, ...assignedContractorDocuments };

    const siteVisitData = {
      Data: JSON.stringify(mergedData),
      CreatedBy: currentLoggedInUser,
    };

    const iswValuesCopy = { ...formik.values, UpdatedBy: currentLoggedInUser };
    iswValuesCopy.ContactDetails = Array.isArray(formik.values.ContactDetails)
      ? JSON.stringify(formik.values.ContactDetails)
      : formik.values.ContactDetails;
    iswValuesCopy.QuoteNumber = simProQuote;
    iswValuesCopy.SiteContactDetails = JSON.stringify([
      siteContactDetailsRef.current.getInputValues(),
    ]);

    const response = await putAsync(`${ISW_URL}/${SITE_ID}/sitevisit`, siteVisitData);
    const response2 = await putAsync(`${ISW_URL}/${SITE_ID}`, iswValuesCopy);
    const response3 = await putAsync(`${QUOTE_URL}`, quoteData);

    processingModalRef.current.toggleModal();

    if (response.status === 200 && response2.status === 200 && response3.status === 200) {
      // getTechSiteVisit({ showProcessingModal: false });
      if (
        (formik.values.Status === 'Desktop Assessment' ||
          formik.values.Status === 'Site Assessment') &&
        (formik.values.SubStatus === 'Scheduled' ||
          formik.values.SubStatus === 'Quote Request Sent')
      ) {
        setSuccessModalBody('Site assessment request email is sent to assigned contractor!');
        successModalRef.current.toggleModal();
      } else {
        setSuccessModalBody('The form has been successfully saved!');
        successModalRef.current.toggleModal();
      }

      setTimeout(() => {
        window.location.href = '/internal/tsa';
      }, 5000);
    } else {
      errorModalRef.current.toggleModal();
    }
  };

  const toggle = () => {
    setFinalPswCollapse(!finalPswCollapse);
  };

  const iswHandleSubmit = async (formik) => {
    const validationErrors = await iswFormRef.current.validateForm();
    if (Object.values(validationErrors).length > 0) {
      requiredFieldsWarningModalRef.current.toggleModal(Object.values(validationErrors));
      return;
    }

    processingModalRef.current.toggleModal();

    const response = await iswFormRef.current.clickSubmitButton(
      formik.values.Status,
      formik.values.SubStatus,
    );
    if (response.status === 200) {
      processingModalRef.current.toggleModal();

      setIswValues(response.data);
      iswModalRef.current.toggleModal();

      setSuccessModalBody('Indicative scope of works is successfully saved');
      successModalRef.current.toggleModal();
      setTimeout(() => {
        setPageLoad(generateRandomString(6));
      }, 3000);
    } else {
      processingModalRef.current.toggleModal();
      errorModalRef.current.toggleModal();
    }
  };

  const pswHandleSubmit = async (actionType) => {
    processingModalRef.current.toggleModal();

    let validationResponse = await pswFormRef.current.validateForm();
    if (actionType === 'draft') {
      validationResponse = [];
    }

    if (Object.keys(validationResponse).length === 0) {
      const inputValues = await pswFormRef.current.getInputValues();
      inputValues.FormStatus = actionType;

      // save the final scope of works
      const response = await putAsync(`${PSW_URL}/${SITE_ID}`, inputValues);
      if (response.status === 200) {
        setPswValues(response.data);
        // handleComplete(formikRef);
        processingModalRef.current.toggleModal();
        pswModalRef.current.toggleModal();
      } else {
        processingModalRef.current.toggleModal();
        errorModalRef.current.toggleModal();
      }
    } else {
      requiredFieldsWarningModalRef.current.toggleModal(Object.values(validationResponse));
      processingModalRef.current.toggleModal();
    }
  };

  const finalPswHandleSubmit = async (formik, actionType) => {
    let validationResponse = await finalPswFormRef.current.validateForm();
    if (actionType === 'draft') {
      validationResponse = [];
    }

    // const validationResponse = await finalPswFormRef.current.validateForm();
    delete validationResponse.finalTechnicalScopeOfWorks;

    if (Object.keys(validationResponse).length > 0) {
      requiredFieldsWarningModalRef.current.toggleModal(Object.values(validationResponse));
      return;
    }

    processingModalRef.current.toggleModal();
    const inputValues = await finalPswFormRef.current.getInputValues();
    inputValues.FormStatus = actionType;
    inputValues.DocumentNumber = formik.values.DocumentNumber;

    // save the final scope of works
    const response = await putAsync(`${FINAL_PSW_URL}/${SITE_ID}`, inputValues);
    if (response.status === 200) {
      setFinalPswValues(response.data);

      if (actionType !== 'draft') {
        formik.values.SubStatus = 'Completed';
      }

      delete formik.errors.finalTechnicalScopeOfWorks;
      delete formikRef.current.errors.finalTechnicalScopeOfWorks;
      // handleComplete(formik);

      processingModalRef.current.toggleModal();
      finalPswModalRef.current.toggleModal();
    } else {
      processingModalRef.current.toggleModal();
      errorModalRef.current.toggleModal();
    }
  };

  const confirmedPswHandleSubmit = async () => {
    processingModalRef.current.toggleModal();

    const validationResponse = await confirmedPswFormRef.current.validateForm();
    delete validationResponse.preliminaryTechnicalScopeOfWorks;

    if (Object.keys(validationResponse).length === 0) {
      const inputValues = await confirmedPswFormRef.current.getInputValues();

      // save the final scope of works
      const response = await putAsync(`${CONFIRMED_PSW_URL}/${SITE_ID}`, inputValues);
      if (response.status === 200) {
        setConfirmedPswValues(response.data);

        processingModalRef.current.toggleModal();
        confirmedPswModalRef.current.toggleModal();
      } else {
        processingModalRef.current.toggleModal();
        errorModalRef.current.toggleModal();
      }
    }
  };

  const handleDownloadPswPdf = async () => {
    if (!pswValues.PswPdf) {
      nothingToDownloadModalRef.current.toggleModal();
      return;
    }

    fileHelper.DownloadFile({
      base64String: pswValues.PswPdf,
      fileName: `Preliminary Scope of Works (${pswValues.ID})`,
      mimeType: 'application/pdf',
    });
  };

  const handleDownloadFinalPswPdf = async () => {
    if (!finalPswValues.Pdf) {
      nothingToDownloadModalRef.current.toggleModal();
      return;
    }

    fileHelper.DownloadFile({
      base64String: finalPswValues.Pdf,
      fileName: `Final Scope of Works (${finalPswValues.ID})`,
      mimeType: 'application/pdf',
    });
  };

  const handleDownloadConfirmedPswPdf = async () => {
    if (!confirmedPswValues.PswPdf) {
      nothingToDownloadModalRef.current.toggleModal();
      return;
    }

    fileHelper.DownloadFile({
      base64String: confirmedPswValues.PswPdf,
      fileName: `Confirmed Preliminary Scope of Works (${confirmedPswValues.ID})`,
      mimeType: 'application/pdf',
    });
  };

  const assignedTechCallBack = (value, mail, formik) => {
    setAssignedTech(value);

    formik.values.AssignedTech = value;
    formik.values.AssignedTechEmail = mail;

    if (value && mail) {
      formik.values.Status = 'Technical Assessment Required';
      formik.values.SubStatus = 'Assigned';

      delete formik.errors.assignedTech;
      setRenderAgain(!renderAgain);
    }
  };

  const peerReviewerCallBack = (value, mail, formik) => {
    setPeerReviewer(value);

    formik.values.PeerReviewer = value;
    formik.values.PeerReviewerEmail = mail;

    if (value) {
      formik.values.previousStatus = formik.values.Status;
      formik.values.previousSubStatus = formik.values.SubStatus;

      formik.values.Status = 'Cost Estimate';
      formik.values.SubStatus = 'In Review';

      delete formikRef.current.errors.peerReviewer;
      // setRenderAgain(!renderAgain)
    }
  };

  const assignedProjectAdminCallBack = (value, mail, formik) => {
    setAssignedProjectAdmin(value);

    formik.values.AssignedProjectAdmin = value;
    formik.values.AssignedProjectAdminEmail = mail;

    if (value && mail) {
      formik.values.Status = 'Technical Assessment required';
      formik.values.SubStatus = 'Waiting Allocation';

      delete formik.errors.assignedProjectAdmin;
      setRenderAgain(!renderAgain);
    }
  };

  const assignedContractorCallBack = (value, formik) => {
    if (formik.values.q7 === 'no' && formik.values.q9 === 'no') {
      formikRef.current.setFieldValue('Status', 'Desktop Assessment');
      formikRef.current.setFieldValue('SubStatus', 'Completed');
    } else if (formik.values.q7 === 'no' && formik.values.q9 === 'yes') {
      formikRef.current.setFieldValue('Status', 'Desktop Assessment');
      formikRef.current.setFieldValue('SubStatus', 'Quote Request Sent');
    } else if (formik.values.q7 === 'yes' && formik.values.q8 === 'yes') {
      formikRef.current.setFieldValue('Status', 'Site Assessment');
      formikRef.current.setFieldValue('SubStatus', 'Scheduled');
    } else if (formik.values.q7 === 'yes' && formik.values.q9 === 'yes') {
      formikRef.current.setFieldValue('Status', 'Site Assessment');
      formikRef.current.setFieldValue('SubStatus', 'Quote Request Sent');
    } else if (formik.values.q7 === 'yes' && formik.values.q9 === 'no') {
      formikRef.current.setFieldValue('Status', 'Site Assessment');
      formikRef.current.setFieldValue('SubStatus', 'Completed');
    }

    setAssignedContractor(value);
    formik.values.AssignedContractor = value;

    // Hack for controls that needs to be rendered again
    if (value) {
      delete formikRef.current.errors.assignedContractor;
      setRenderAgain(!renderAgain);
    }
  };

  const resetSucceedingQuestions = (index, formik) => {
    for (let i = index + 1; i <= 10; i++) {
      formik.setFieldValue(`q${i}`, '');
    }
  };

  const changeEngineeringStatus = async (uppyUploadResponse) => {
    switch (uppyUploadResponse.uppyGroupId) {
      case 'engineeringDesignFiles':
        break;
      case 'supplyAuthorityDocumentationFiles':
        formikRef.current.setFieldValue('Status', 'Engineering Advice');
        formikRef.current.setFieldValue('SubStatus', 'Power Authority Application');
        break;
      case 'dataLoggingOutputFiles':
        formikRef.current.setFieldValue('Status', 'Engineering Advice');
        formikRef.current.setFieldValue('SubStatus', 'Data Logging');
        break;
      default:
    }
  };

  const handleQuoteApprove = async (formik) => {
    if (formik.values.Status === 'Cost Estimate' && formik.values.SubStatus === 'In Review') {
      formik.values.Status = 'Completed TSA';
      formik.values.SubStatus = 'Approved Estimates';
    } else if (
      formik.values.Status === 'Cost Estimate' &&
      formik.values.SubStatus === 'Revision Requested'
    ) {
      formik.values.Status = 'Cost Estimate';
      formik.values.SubStatus = 'In Review';
    } else {
      formik.values.SubStatus = 'Quote Approved';
    }

    handleComplete(formik);
  };

  const handleQuoteReject = async (formik) => {
    formik.values.SubStatus = 'Revision Requested';
    handleComplete(formik);
  };

  const handlePeerReject = async (formik) => {
    formik.values.SubStatus = 'Revision Requested';
    handleComplete(formik);
  };

  const handlePeerApprove = async (formik) => {
    if (formik.values.Status === 'Cost Estimate' && formik.values.SubStatus === 'In Review') {
      formik.values.Status = 'Completed TSA';
      formik.values.SubStatus = 'Approved Estimates';
    } else if (
      formik.values.Status === 'Cost Estimate' &&
      formik.values.SubStatus === 'Revision Requested'
    ) {
      formik.values.Status = 'Cost Estimate';
      formik.values.SubStatus = 'In Review';
    }

    handleComplete(formik);
  };

  const handleSalesRepToTechResubmit = async (formik) => {
    formik.values.q4 = '';
    formik.values.Status = 'Technical Assessment Required';
    formik.values.SubStatus = 'Assigned';
    handleComplete(formik);
  };

  const engineeringSelectionChange = async () => {
    const result = await engineeringUploadFormRef.current.getFormikValues();

    // check the 3 Engineering Question if it has been answered
    if (
      result.dataLoggingOutput &&
      result.supplyAuthorityDocumentation &&
      result.engineeringDesign
    ) {
      setEngineeringQuestionsAnswered(true);
    }
  };

  const handleSimProQuoteChange = (value, formik) => {
    formik.values.simProQuote = value;
    setSimProQuote(value);

    if (value) {
      delete formik.errors.simProQuote;
      setRenderAgain(!renderAgain);
    }
  };

  const composeBottomFormButtons = (formik) => {
    let buttons = [];

    if (formik.values.Archived) {
      return buttons;
    }

    // default button
    if (!readOnly)
      buttons.push(
        <input
          type="button"
          key="default_submit_save"
          className="btn btn-primary mb-3"
          style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
          value="Submit / Save"
          onClick={() => {
            handleComplete(formik);
          }}
        />,
      );

    if (
      formik.values.Status === 'Completed TSA' &&
      (formik.values.SubStatus === 'Approved Estimates' ||
        formik.values.SubStatus === 'Quote sent to Client, awaiting feedback' ||
        formik.values.SubStatus === 'On Hold / Decision still pending' ||
        formik.values.SubStatus === 'Client did not go ahead')
    ) {
      buttons.push(
        <input
          type="button"
          key="completed_submit_save"
          className="btn btn-primary mb-3"
          style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
          value="Submit / Save"
          onClick={() => {
            handleComplete(formik);
          }}
        />,
      );
    }

    if (!formik.values.previousStatus && formik.values.Status === 'Cost Estimate') {
      if (!formik.values.previousSubStatus && formik.values.SubStatus === 'In Review') {
        if (
          currentLoggedInUser === iswValues.PeerReviewer ||
          TSA_ADMINS.includes(currentLoggedInUser)
        ) {
          buttons = [];

          buttons.push(
            <input
              type="button"
              key="Request_Revision_peerReviewer"
              className="btn btn-primary mb-3"
              style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
              value="Request Revision"
              onClick={() => {
                peerRejectModalRef.current.toggleModal();
              }}
            />,
          );
          buttons.push(
            <input
              type="button"
              key="Resend_Approve_peerReviewer"
              className="btn btn-primary mb-3"
              style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
              value={
                formik.values.Status === 'Cost Estimate' &&
                formik.values.SubStatus === 'Revision Requested'
                  ? 'Resend for Review'
                  : 'Approve'
              }
              onClick={() => {
                handlePeerApprove(formik);
              }}
            />,
          );
        }
      }

      if (!formik.values.previousSubStatus && formik.values.SubStatus === 'Revision Requested') {
        if (
          currentLoggedInUser === iswValues.AssignedTech ||
          TSA_ADMINS.includes(currentLoggedInUser)
        ) {
          buttons = [];

          buttons.push(
            <input
              type="button"
              key="Resend_Approve_assignedTech"
              className="btn btn-primary mb-3"
              style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
              value={
                formik.values.Status === 'Cost Estimate' &&
                formik.values.SubStatus === 'Revision Requested'
                  ? 'Resend for Review'
                  : 'Approve'
              }
              onClick={() => {
                handlePeerApprove(formik);
              }}
            />,
          );
        }
      }
    }

    if (
      formik.values.Status === 'Desktop Assessment' ||
      formik.values.Status === 'Site Assessment'
    ) {
      if (formik.values.SubStatus === 'Review') {
        buttons = [];
      }
    }

    if (
      formik.values.Status === 'Technical Assessment Required' &&
      formik.values.SubStatus === 'Rejected'
    ) {
      if (currentLoggedInUser === iswValues.CreatedBy || TSA_ADMINS.includes(currentLoggedInUser)) {
        buttons = [];
        buttons.push(
          <input
            type="button"
            key="Resubmit_assignedTech"
            className={`btn btn-primary mb-3 ${readOnly ? 'd-none' : ''}`}
            style={{ width: '135px', fontSize: '12px', marginRight: '10px' }}
            value="Resubmit"
            onClick={() => {
              handleSalesRepToTechResubmit(formik);
            }}
          />,
        );
      }
    }

    return buttons;
  };

  const handleTechReject = (formik) => {
    formik.values.Status = 'Technical Assessment Required';
    formik.values.SubStatus = 'Rejected';
    formik.values.RejectReason = rejectModalRef.current.getCommentBody();

    handleComplete(formik);
  };

  const handleDownloadQuoteMarkUpReview = async () => {
    // Create an anchor element and trigger a download
    const link = document.createElement('a');
    link.href = QUOTE_MARKUP_REVIEW_DOC_URL; // Path to your static file
    link.download = 'KittyHawk17 Item 21 & 22 - Quote Markup & Review Levels_v7.pdf'; // The file name that will be suggested to the user
    document.body.appendChild(link); // Append to html body
    link.click(); // Simulate click on the link to start download
    document.body.removeChild(link); // Clean up
  };

  const handleArchiveClick = async (isArchived) => {
    processingModalRef.current.toggleModal();

    const archiveReason = archiveModalRef.current.getCommentBody();
    formikRef.current.values.Archived = isArchived;
    formikRef.current.values.ArchiveReason = archiveReason;
    formikRef.current.values.UpdatedBy = currentLoggedInUser;

    const response = await putAsync(`${ISW_URL}/${SITE_ID}/archive`, formikRef.current.values);
    if (response.status === 200) {
      if (isArchived) {
        setSuccessModalBody(
          'This Technical assessment entry has been archived and added to the archive list.',
        );
      } else {
        setSuccessModalBody(
          'This Technical assessment entry has been removed from the archive list.',
        );
      }

      successModalRef.current.toggleModal();
      setTimeout(() => {
        window.location.href = '/internal/tsa';
      }, 5000);
    } else {
      errorModalRef.current.toggleModal();
    }

    processingModalRef.current.toggleModal();
  };

  const renderContacts = () => {
    const multicontacts = [];

    for (let i = 0; i < contacts.length; i++) {
      multicontacts.push(
        <ContactDetails
          key={`contacts_${i}`}
          index={i + 1}
          values={contacts[i]}
          showLabelTitle="Tender Contact Details"
        />,
      );
    }

    return multicontacts;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <BreadCrumbs />
            <Formik
              key={`formik_${pageLoad}`}
              initialValues={iswValues}
              onSubmit={() => {
                // Do nothing. Everything is handled in the handleSubmit function
              }}
              innerRef={formikRef}
            >
              {
                //eslint-disable-next-line no-unused-vars
                (formik) => {
                  return (
                    <FormikForm id="siteVisitForm">
                      {formik.values.Status === 'Enquiry In' ||
                      formik.values.Status === 'Site Visit / Collect Information' ? (
                        <h2>
                          <strong>Questionnaire / {formik.values.DocumentNumber}</strong>
                        </h2>
                      ) : (
                        <div style={{ display: 'inline-flex' }}>
                          <h2>
                            <strong>Technical Assessment / {formik.values.DocumentNumber}</strong>
                          </h2>
                          <span style={{ verticalAlign: 'top' }}>
                            <Button
                              className="btn-primary"
                              onClick={() => {
                                archiveModalRef.current.toggleModal();
                              }}
                              color="white"
                              size="sm"
                              round="true"
                              icon="true"
                              disabled={false}
                              style={{ marginLeft: '10px' }}
                            >
                              {iswValues?.Archived ? 'Un-archive' : 'Archive'}
                            </Button>
                          </span>
                        </div>
                      )}
                      <Row>
                        <div className="col-md-3 order-md-2">
                          {/* Im not very good in CSS so this is the best i can come up with */}
                          <TimelineControl
                            data={siteVisitHistory}
                            height={getElementHeight('statusClientContainer') - 70}
                          />
                        </div>
                        <div id="statusClientContainer" className="col-md-9 order-md-1">
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label htmlFor="Status">Status</Label>
                                <Field
                                  name="Status"
                                  className="form-control"
                                  readOnly
                                  value={formik.values.Status}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup>
                                <Label htmlFor="SubStatus">Sub-Status</Label>
                                {formik.values.Status === 'Completed TSA' &&
                                (formik.values.SubStatus === 'Approved Estimates' ||
                                  formik.values.SubStatus ===
                                    'Quote sent to Client, awaiting feedback' ||
                                  formik.values.SubStatus === 'On Hold / Decision still pending' ||
                                  formik.values.SubStatus === 'Client did not go ahead') ? (
                                  <Field
                                    as="select"
                                    className="form-select"
                                    name="SubStatus"
                                    onChange={formik.handleChange}
                                  >
                                    <option value="Approved Estimates">Approved Estimates</option>
                                    <hr />
                                    <option value="Quote sent to Client, awaiting feedback">
                                      Quote sent to Client, awaiting feedback
                                    </option>
                                    <option value="On Hold / Decision still pending">
                                      On Hold / Decision still pending
                                    </option>
                                    <option value="Client did not go ahead">
                                      Client did not go ahead
                                    </option>
                                  </Field>
                                ) : (
                                  <Field
                                    name="SubStatus"
                                    className="form-control"
                                    readOnly
                                    value={formik.values.SubStatus}
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TenderDetails data={tenderDetails} ref={tenderDetailsRef} />
                            </Col>
                          </Row>
                        </div>
                      </Row>
                      {renderContacts()}
                      <Row>
                        <Col>
                          <SiteContactDetails
                            ref={siteContactDetailsRef}
                            id="siteContactDetails_1"
                            showAddSiteButton={
                              !readOnly &&
                              (!formikRef?.current?.values?.SubStatus ||
                                formikRef?.current?.values?.SubStatus === 'Draft')
                            }
                            multiSite
                            index={1}
                            values={mainSiteContact}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col></Col>
                      </Row>

                      <Row>
                        <Col>
                          <Milestone
                            data={tenderDetails?.milestones ?? []}
                            parentId={SITE_ID}
                            showAddButton
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TenderDocumentation
                            title="Tender Documentation"
                            fileUploadUrl={`${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`}
                            fileDownloadUrl={`${process.env.REACT_APP_TSA_URL}/tender/${SITE_ID}/file`}
                            existingFiles={tenderDocumentationFiles}
                            existingNotes={tenderDetails?.tenderNotes}
                            existingTenderLocation={tenderDetails?.tenderLocation}
                            ref={tenderDocumentationRef}
                          />
                        </Col>
                      </Row>
                      {
                        // 1st set
                        formik.values.SubStatus !== 'Draft' &&
                        formik.values.SubStatus !== 'Questionnaire Completed' &&
                        formik.values.SubStatus !== 'Document request Sent' ? (
                          <Card>
                            <CardBody className="card-rounded">
                              <Row>
                                <Col md="12" style={{ textAlign: 'center' }}>
                                  <FormText color="muted">To be answered by Sales Rep</FormText>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="3">
                                  <Label htmlFor="q3">Is Technical Assessment Required?</Label>
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.q3}
                                  </div>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <FormGroup check inline>
                                      <Field
                                        disabled={
                                          (iswValues.CreatedBy !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        type="radio"
                                        className="form-check-input"
                                        name="q3"
                                        value="yes"
                                        onClick={() => {
                                          // formik.setFieldValue("Status", "Technical Assessment Required");
                                          // formik.setFieldValue("SubStatus", "Assigned");
                                          resetSucceedingQuestions(3, formik);
                                        }}
                                      />
                                      &nbsp;Yes
                                    </FormGroup>
                                    <FormGroup check inline>
                                      <Field
                                        disabled={
                                          (iswValues.CreatedBy !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        type="radio"
                                        className="form-check-input"
                                        name="q3"
                                        value="no"
                                        onClick={() => {
                                          resetSucceedingQuestions(3, formik);
                                          formik.setFieldValue('Status', 'Cancelled');
                                          formik.setFieldValue('SubStatus', '');
                                        }}
                                      />
                                      &nbsp;No
                                    </FormGroup>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {formik.values.q3 === 'yes' ? (
                                <>
                                  <Row>
                                    <Col md="3">
                                      <Label>Simpro Quote</Label>
                                      <div
                                        key={`simProQuote_${renderAgain}`}
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.simProQuote ? '' : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <SimProQuoteLookUp
                                        disabled={
                                          (iswValues.CreatedBy !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        id="simProQuote"
                                        changeCallBack={(e) => {
                                          handleSimProQuoteChange(e, formik);
                                        }}
                                        initialValue={simProQuote}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3">
                                      <Label>Assign a Technical Assessment Coordinator</Label>
                                      <div
                                        key={`assignedProjectAdmin_${renderAgain}`}
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.assignedProjectAdmin
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <ActiveDirectoryAutoSuggest
                                        disabled={
                                          (iswValues.CreatedBy !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        id="assignedProjectAdmin"
                                        changeCallBack={(e, f) => {
                                          assignedProjectAdminCallBack(e, f, formik);
                                        }}
                                        defaultValue={assignedProjectAdmin}
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                  <Row>
                                    <Col md="12" style={{ textAlign: 'center' }}>
                                      <FormText color="muted">
                                        To be answered by Technical Assessment Coordinator
                                      </FormText>
                                    </Col>
                                  </Row>
                                  &nbsp;
                                  <Row>
                                    <Col md="3">
                                      <Label>Assign a Technical Assessor</Label>
                                      <div
                                        key={`assignedTech_${renderAgain}`}
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.assignedTech ? '' : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <ActiveDirectoryAutoSuggest
                                        disabled={
                                          (assignedProjectAdmin
                                            ?.toLowerCase()
                                            .replaceAll(' ', '') !==
                                            currentLoggedInUser
                                              ?.toLowerCase()
                                              .replaceAll(' ', '') &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        id="assignedTech"
                                        changeCallBack={(e, f) => {
                                          assignedTechCallBack(e, f, formik);
                                        }}
                                        defaultValue={assignedTech}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ''
                              )}
                              {formik.values.q3 === 'yes' ? (
                                <>
                                  <hr />
                                  <Row>
                                    <Col md="12" style={{ textAlign: 'center' }}>
                                      <FormText color="muted">
                                        To be answered by Technical Assessor
                                      </FormText>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="3">
                                      <Label htmlFor="q4">
                                        Is there sufficient information to Proceed?
                                      </Label>
                                      <div
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className="invalid-feedback"
                                      >
                                        {formik.errors.q4}
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <FormGroup>
                                        <FormGroup check inline>
                                          <Field
                                            disabled={
                                              (assignedTech !== currentLoggedInUser &&
                                                !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                              readOnly
                                            }
                                            type="radio"
                                            className="form-check-input"
                                            name="q4"
                                            value="yes"
                                            onClick={() => {
                                              formik.setFieldValue(
                                                'Status',
                                                'Technical Assessment Required',
                                              );
                                              formik.setFieldValue('SubStatus', 'Accepted');

                                              pswModalRef.current.toggleModal();
                                              resetSucceedingQuestions(4, formik);
                                            }}
                                          />
                                          &nbsp;Yes
                                        </FormGroup>
                                        <FormGroup check inline>
                                          <Field
                                            disabled={
                                              (assignedTech !== currentLoggedInUser &&
                                                !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                              readOnly
                                            }
                                            type="radio"
                                            className="form-check-input"
                                            name="q4"
                                            value="no"
                                            onClick={() => {
                                              rejectModalRef.current.toggleModal();
                                              resetSucceedingQuestions(4, formik);
                                            }}
                                          />
                                          &nbsp;No
                                        </FormGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ''
                              )}
                              {
                                // Show only when Is there sufficient information to Proceed = Yes
                                formik.values.q4 === 'yes' && pswValues.FormStatus === 'submit' ? (
                                  <Row>
                                    <Col md="3">
                                      <Label htmlFor="q5">Is Engineering advice required?</Label>
                                      <div
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className="invalid-feedback"
                                      >
                                        {formik.errors.q5}
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <FormGroup>
                                        <FormGroup check inline>
                                          <Field
                                            disabled={
                                              (assignedTech !== currentLoggedInUser &&
                                                !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                              readOnly
                                            }
                                            type="radio"
                                            className="form-check-input"
                                            name="q5"
                                            value="yes"
                                            onClick={() => {
                                              formik.setFieldValue('Status', 'Engineering Advice');
                                              formik.setFieldValue('SubStatus', 'In Progress');
                                              resetSucceedingQuestions(5, formik);
                                            }}
                                          />
                                          &nbsp;Yes
                                        </FormGroup>
                                        <FormGroup check inline>
                                          <Field
                                            disabled={
                                              (assignedTech !== currentLoggedInUser &&
                                                !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                              readOnly
                                            }
                                            type="radio"
                                            className="form-check-input"
                                            name="q5"
                                            value="no"
                                            onClick={() => {
                                              //formik.setFieldValue("Status", "Technical Assessment Required");
                                              //formik.setFieldValue("SubStatus", "Rejected")
                                              resetSucceedingQuestions(5, formik);
                                            }}
                                          />
                                          &nbsp;No
                                        </FormGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                ) : (
                                  ''
                                )
                              }
                              {formik.values.q5 === 'yes' ? (
                                <Row>
                                  <Col md="3">
                                    <Label htmlFor="q6">Is it Paid Engineering</Label>
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.q6}
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q6"
                                          value="yes"
                                          onClick={() => {
                                            resetSucceedingQuestions(6, formik);
                                          }}
                                        />
                                        &nbsp;Yes
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q6"
                                          value="no"
                                          onClick={() => {
                                            resetSucceedingQuestions(6, formik);
                                          }}
                                        />
                                        &nbsp;No
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ) : (
                                ''
                              )}
                            </CardBody>
                          </Card>
                        ) : (
                          ''
                        )
                      }
                      {assignedTech ? (
                        <Row>
                          <Col>
                            <Addendum
                              title="Addendum"
                              fileUploadUrl={`${process.env.REACT_APP_BASEAPI_URL}/uploads/${SITE_ID}`}
                              fileDownloadUrl={`${process.env.REACT_APP_TSA_URL}/tender/addendum/${SITE_ID}/file`}
                              parentId={SITE_ID}
                              disabled={
                                currentLoggedInUser !== iswValues.CreatedBy &&
                                !TSA_ADMINS.includes(currentLoggedInUser)
                              }
                              addendums={tenderDetails?.addendums}
                              acknowledge={
                                currentLoggedInUser === assignedTech ||
                                TSA_ADMINS.includes(currentLoggedInUser)
                              }
                            />
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      {formik.values.q4 === 'yes' ? (
                        <Card>
                          <CardTitle
                            tag="h5"
                            className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                            onClick={() => {
                              setPswCollapse(!pswCollapse);
                            }}
                          >
                            <span style={{ float: 'left' }}>
                              Preliminary Technical Scope of Works
                            </span>
                            {pswCollapse ? (
                              <Icon.Minus size={30} style={{ float: 'right' }} />
                            ) : (
                              <Icon.Plus size={30} style={{ float: 'right' }} />
                            )}
                          </CardTitle>
                          <Collapse isOpen={pswCollapse}>
                            <CardBody className="card-rounded-bottom">
                              <Table>
                                <Thead>
                                  <Tr style={styles.row}>
                                    <Th style={styles.cell}>Document</Th>
                                    <Th style={styles.cell}>Actions</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <Tr style={styles.row}>
                                    <Td style={styles.cell}>
                                      Preliminary Technical Scope of Works
                                      <div
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.preliminaryTechnicalScopeOfWorks
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Td>
                                    <Td style={styles.cell}>
                                      {!readOnly &&
                                      (formik.values.Status === 'Enquiry In' ||
                                        formik.values.Status === 'Technical Assessment Required' ||
                                        ((formik.values.Status === 'Desktop Assessment' ||
                                          formik.values.Status === 'Site Assessment') &&
                                          formik.values.SubStatus === 'In Progress')) ? (
                                        <>
                                          <TooltipItem target="editPswTooltip" placement="top">
                                            <div>
                                              <p style={{ textAlign: 'left' }}>
                                                To view and download attachments, hit the edit
                                                button
                                              </p>
                                            </div>
                                          </TooltipItem>
                                          <Button
                                            className="btn-primary"
                                            onClick={toggleEditPreliminaryScopeOfWorksModal}
                                            color="white"
                                            id="editPswTooltip"
                                            size="sm"
                                            round="true"
                                            icon="true"
                                            disabled={false}
                                            style={{ width: '50px' }}
                                          >
                                            Edit
                                          </Button>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                      &nbsp;
                                      <TooltipItem target="downloadPswPdfTooltip" placement="top">
                                        <div>
                                          <p style={{ textAlign: 'left' }}>
                                            To get a summarised view of the scope of works, hit the
                                            download PDF button
                                          </p>
                                        </div>
                                      </TooltipItem>
                                      <Button
                                        className="btn-primary"
                                        onClick={handleDownloadPswPdf}
                                        color="white"
                                        id="downloadPswPdfTooltip"
                                        size="sm"
                                        round="true"
                                        icon="true"
                                        disabled={false}
                                      >
                                        Download PDF
                                      </Button>
                                    </Td>
                                  </Tr>
                                  {(formik.values.SubStatus === 'Review' ||
                                    formik.values.SubStatus === 'Quote Received' ||
                                    formik.values.SubStatus === 'Quote Approved' ||
                                    formik.values.SubStatus === 'Revision Requested' ||
                                    formik.values.SubStatus === 'Completed' ||
                                    formik.values.SubStatus === 'In Review' ||
                                    formik.values.SubStatus === 'Approved Estimates') &&
                                  confirmedPswValues.PswPdf &&
                                  quoteValues?.Quotes &&
                                  quoteValues?.Quotes.length > 0 ? (
                                    <Tr style={styles.row}>
                                      <Td style={styles.cell}>
                                        Technical Scope of works - Contractor version
                                      </Td>
                                      <Td style={styles.cell}>
                                        <Button
                                          className="btn-primary"
                                          onClick={toggleEditConfirmedPreliminaryScopeOfWorksModal}
                                          color="white"
                                          size="sm"
                                          round="true"
                                          icon="true"
                                          disabled={false}
                                          style={{ width: '50px' }}
                                        >
                                          View
                                        </Button>
                                        &nbsp;
                                        <Button
                                          className="btn-primary"
                                          onClick={handleDownloadConfirmedPswPdf}
                                          color="white"
                                          size="sm"
                                          round="true"
                                          icon="true"
                                          disabled={false}
                                        >
                                          Download PDF
                                        </Button>
                                      </Td>
                                    </Tr>
                                  ) : (
                                    ''
                                  )}
                                </Tbody>
                              </Table>
                            </CardBody>
                          </Collapse>
                        </Card>
                      ) : (
                        ''
                      )}
                      {formik.values.q5 === 'yes' ? (
                        <Row>
                          <Col xs="12" md="12" lg="12">
                            <EngineeringUploadForm
                              initialValues={engineeringFiles}
                              ref={engineeringUploadFormRef}
                              selectionChangeCallback={engineeringSelectionChange}
                              fileUploadUrl={ENG_FILE_UPLOAD_URL}
                              fileDownloadUrl={ENG_FILE_DOWNLOAD_URL}
                              uploadCompleteCallback={(e) => {
                                changeEngineeringStatus(e);
                              }}
                            />
                          </Col>
                        </Row>
                      ) : (
                        ''
                      )}
                      {
                        // 2nd set
                        formik.values.SubStatus !== 'Draft' &&
                        formik.values.SubStatus !== 'Questionnaire Completed' &&
                        formik.values.SubStatus !== 'Document request Sent' ? (
                          <Card>
                            <CardBody className="card-rounded">
                              <Row>
                                <Col md="12" style={{ textAlign: 'center' }}>
                                  <FormText color="muted">
                                    To be answered by Technical Assessor
                                  </FormText>
                                </Col>
                              </Row>

                              {(formik.values.q5 === 'yes' && engineeringQuestionsAnswered) ||
                              // || formik.values.Status === "Site Assessment"
                              // || formik.values.Status === "Desktop Assessment"
                              formik.values.q5 === 'no' ? (
                                <Row>
                                  <Col md="3">
                                    <Label htmlFor="q7">Is Site Assessment required</Label>
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.q7}
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q7"
                                          value="yes"
                                          onClick={() => {
                                            formik.setFieldValue('Status', 'Site Assessment');
                                            formik.setFieldValue('SubStatus', 'In Progress');
                                            resetSucceedingQuestions(7, formik);
                                          }}
                                        />
                                        &nbsp;Yes
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q7"
                                          value="no"
                                          onClick={() => {
                                            formik.setFieldValue('Status', 'Desktop Assessment');
                                            formik.setFieldValue('SubStatus', 'In Progress');
                                            resetSucceedingQuestions(7, formik);
                                          }}
                                        />
                                        &nbsp;No
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ) : (
                                ''
                              )}
                              {formik.values.q7 === 'yes' ? (
                                <Row>
                                  <Col md="3">
                                    <Label htmlFor="q8">
                                      Is the contractor leading the site Assessment?
                                    </Label>
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.q8}
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q8"
                                          id="q8_yes"
                                          value="yes"
                                          onClick={() => {
                                            formik.setFieldValue('SubStatus', 'In Progress');

                                            setAssignedContractor('');
                                            resetSucceedingQuestions(8, formik);
                                          }}
                                        />
                                        &nbsp;Yes
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q8"
                                          id="q8_no"
                                          value="no"
                                          onClick={() => {
                                            formik.setFieldValue('SubStatus', 'In Progress');

                                            setAssignedContractor('');
                                            resetSucceedingQuestions(8, formik);
                                          }}
                                        />
                                        &nbsp;No
                                      </FormGroup>
                                      <Icon.HelpCircle
                                        id="toolTip-contractorLeadingTheAssessment"
                                        size={20}
                                        style={{ color: 'blue' }}
                                      />
                                      <TooltipItem
                                        target="toolTip-contractorLeadingTheAssessment"
                                        placement="top"
                                      >
                                        <div>
                                          <p style={{ textAlign: 'left' }}>
                                            Upon site assessment completion please update the
                                            Preliminary technical scope of works.
                                          </p>
                                        </div>
                                      </TooltipItem>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ) : (
                                ''
                              )}
                              {formik.values.q7 === 'no' || formik.values.q8 === 'no' ? (
                                <Row>
                                  <Col md="3">
                                    <Label htmlFor="q9">
                                      Is contractor support required for pricing the job?
                                    </Label>
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.q9}
                                    </div>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q9"
                                          id="q9_yes"
                                          value="yes"
                                          onClick={() => {
                                            formik.setFieldValue('SubStatus', 'In Progress');

                                            setAssignedContractor('');
                                            resetSucceedingQuestions(9, formik);
                                          }}
                                        />
                                        &nbsp;Yes
                                      </FormGroup>
                                      <FormGroup check inline>
                                        <Field
                                          disabled={
                                            (assignedTech !== currentLoggedInUser &&
                                              !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                            readOnly
                                          }
                                          type="radio"
                                          className="form-check-input"
                                          name="q9"
                                          id="q9_no"
                                          value="no"
                                          onClick={() => {
                                            formik.setFieldValue('SubStatus', 'In Progress');

                                            setAssignedContractor('');
                                            resetSucceedingQuestions(9, formik);

                                            finalPswModalRef.current.toggleModal();
                                          }}
                                        />
                                        &nbsp;No
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ) : (
                                ''
                              )}
                              {formik.values.q8 === 'yes' || formik.values.q9 === 'yes' ? (
                                <>
                                  <Row>
                                    <Col md="3">
                                      <Label>Assign a Contractor</Label>
                                      <div
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.assignedContractor
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <ContractorAutoSuggest
                                        disabled={
                                          (assignedTech !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        id="assignedContractor"
                                        changeCallBack={(value) => {
                                          assignedContractorCallBack(value, formik);
                                        }}
                                        initialValue={assignedContractor}
                                      />
                                      {assignedTech === currentLoggedInUser ||
                                      TSA_ADMINS.includes(currentLoggedInUser) ? (
                                        <FormText
                                          color="link"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            documentSelectModalRef.current.toggleModal();
                                          }}
                                        >
                                          <u>
                                            Please click here to assign documents to the contractor
                                          </u>
                                        </FormText>
                                      ) : (
                                        ''
                                      )}
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                ''
                              )}
                            </CardBody>
                          </Card>
                        ) : (
                          ''
                        )
                      }
                      {(formik.values.Status === 'Site Assessment' &&
                        (formik.values.SubStatus === 'Review' ||
                          formik.values.SubStatus === 'Quote Request Sent' ||
                          formik.values.SubStatus === 'Scheduled' ||
                          formik.values.SubStatus === 'Quote Received' ||
                          formik.values.SubStatus === 'Quote Approved' ||
                          formik.values.SubStatus === 'Revision Requested' ||
                          formik.values.SubStatus === 'In Review' ||
                          formik.values.SubStatus === 'Approved Estimates')) ||
                      (formik.values.Status === 'Desktop Assessment' &&
                        (formik.values.SubStatus === 'Review' ||
                          formik.values.SubStatus === 'Quote Request Sent' ||
                          formik.values.SubStatus === 'Quote Received' ||
                          formik.values.SubStatus === 'Quote Approved' ||
                          formik.values.SubStatus === 'Revision Requested' ||
                          formik.values.SubStatus === 'In Review' ||
                          formik.values.SubStatus === 'Approved Estimates')) ||
                      (quoteValues?.Quotes && quoteValues?.Quotes.length > 0) ? (
                        <QuoteUploadForm
                          id="iswquote"
                          title="Contractor Quotes"
                          ref={quoteUploadFormRef}
                          isOpen
                          initialValues={quoteValues}
                          fileUploadUrl={QUOTE_FILE_UPLOAD_URL}
                          fileDownloadUrl={QUOTE_FILE_DOWNLOAD_URL}
                          rejectLabel="Request Revision"
                          approveLabel="Approve"
                          showApproveButton={
                            (assignedTech === currentLoggedInUser ||
                              TSA_ADMINS.includes(currentLoggedInUser)) &&
                            ((formik.values.Status === 'Site Assessment' &&
                              formik.values.SubStatus === 'Review') ||
                              (formik.values.Status === 'Desktop Assessment' &&
                                formik.values.SubStatus === 'Quote Received')) &&
                            !readOnly
                          }
                          showRejectButton={
                            (assignedTech === currentLoggedInUser ||
                              TSA_ADMINS.includes(currentLoggedInUser)) &&
                            ((formik.values.Status === 'Site Assessment' &&
                              formik.values.SubStatus === 'Review') ||
                              (formik.values.Status === 'Desktop Assessment' &&
                                formik.values.SubStatus === 'Quote Received')) &&
                            !readOnly
                          }
                          approveCallback={() => {
                            handleQuoteApprove(formik);
                          }}
                          rejectCallback={() => {
                            handleQuoteReject(formik);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {formik.values.SubStatus === 'Quote Approved' ||
                      formik.values.SubStatus === 'Completed' ||
                      formik.values.Status === 'Cost Estimate' ||
                      formik.values.Status === 'Completed TSA' ||
                      formik.values.q9 === 'no' ? (
                        <Card>
                          <CardTitle
                            tag="h5"
                            className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
                          >
                            <span style={{ float: 'left' }}>Final Technical Scope of Works</span>
                            <div style={{ flexGrow: 1 }}></div>
                            {finalPswCollapse ? (
                              <Icon.Minus
                                size={30}
                                style={{ float: 'right' }}
                                onClick={toggle.bind(null)}
                              />
                            ) : (
                              <Icon.Plus
                                size={30}
                                style={{ float: 'right' }}
                                onClick={toggle.bind(null)}
                              />
                            )}
                            <Badge
                              color="primary"
                              pill
                              style={{
                                float: 'right',
                                zIndex: 999,
                                cursor: 'pointer',
                                marginRight: '25px',
                              }}
                              onClick={handleDownloadQuoteMarkUpReview}
                            >
                              Quote Mark up & Review &nbsp;&nbsp;
                              <Icon.Download size={20} />
                            </Badge>
                          </CardTitle>
                          <Collapse isOpen={finalPswCollapse}>
                            <CardBody className="card-rounded-bottom">
                              <Table>
                                <Thead>
                                  <Tr style={styles.row}>
                                    <Th style={styles.cell}>Document</Th>
                                    <Th style={styles.cell}>Actions</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  <Tr style={styles.row}>
                                    <Td style={styles.cell}>
                                      Final Technical Scope of Works
                                      <div
                                        style={{ display: 'block', textAlign: 'left' }}
                                        className={`invalid-feedback ${
                                          formikRef.current.errors.finalTechnicalScopeOfWorks
                                            ? ''
                                            : 'd-none'
                                        }`}
                                      >
                                        Required
                                      </div>
                                    </Td>
                                    <Td style={styles.cell}>
                                      {!readOnly ? (
                                        <Button
                                          className="btn-primary"
                                          onClick={toggleEditFinalScopeOfWorksModal}
                                          color="white"
                                          size="sm"
                                          round="true"
                                          icon="true"
                                          disabled={false}
                                        >
                                          Edit
                                        </Button>
                                      ) : (
                                        ''
                                      )}
                                      &nbsp;
                                      <Button
                                        className="btn-primary"
                                        onClick={handleDownloadFinalPswPdf}
                                        color="white"
                                        size="sm"
                                        round="true"
                                        icon="true"
                                        disabled={false}
                                      >
                                        Download PDF
                                      </Button>
                                      &nbsp;
                                    </Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </CardBody>
                          </Collapse>
                        </Card>
                      ) : (
                        ''
                      )}
                      {formik.values.SubStatus !== 'Draft' &&
                      formik.values.SubStatus !== 'Questionnaire Completed' &&
                      formik.values.SubStatus !== 'Document request Sent' ? (
                        <Card>
                          <CardBody className="card-rounded">
                            <Row>
                              <Col md="12" style={{ textAlign: 'center' }}>
                                <FormText color="muted">
                                  To be answered by Technical Assessor
                                </FormText>
                              </Col>
                            </Row>
                            &nbsp;
                            {finalPswValues.FormStatus === 'submit' ? (
                              <Row>
                                <Col md="3">
                                  <Label htmlFor="q11">Is peer review required?</Label>
                                  <div>
                                    <Icon.HelpCircle
                                      id="toolTip-quoteMarkUp"
                                      size={20}
                                      style={{ color: 'blue' }}
                                    />
                                    &nbsp;
                                    <a href={QUOTE_MARKUP_REVIEW_DOC_URL} style={{ color: 'blue' }}>
                                      Quote markup & Review levels
                                    </a>
                                  </div>
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.q11}
                                  </div>
                                </Col>
                                <Col md="3">
                                  <FormGroup>
                                    <FormGroup check inline>
                                      <Field
                                        disabled={
                                          (assignedTech !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        type="radio"
                                        className="form-check-input"
                                        name="q11"
                                        id="q11_yes"
                                        value="yes"
                                        onClick={() => {
                                          if (formik.values.PreviousStatus)
                                            formik.setFieldValue(
                                              'Status',
                                              formik.values.PreviousStatus,
                                            );

                                          if (formik.values.PreviousSubStatus)
                                            formik.setFieldValue(
                                              'SubStatus',
                                              formik.values.PreviousSubStatus,
                                            );

                                          setPeerReviewer('');
                                          resetSucceedingQuestions(11, formik);
                                        }}
                                      />
                                      &nbsp;Yes
                                    </FormGroup>
                                    <FormGroup check inline>
                                      <Field
                                        disabled={
                                          (assignedTech !== currentLoggedInUser &&
                                            !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                          readOnly
                                        }
                                        type="radio"
                                        className="form-check-input"
                                        name="q11"
                                        id="q11_no"
                                        value="no"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            'PreviousStatus',
                                            formik.values.Status,
                                          );
                                          formik.setFieldValue(
                                            'PreviousSubStatus',
                                            formik.values.SubStatus,
                                          );

                                          formik.setFieldValue('Status', 'Completed TSA');
                                          formik.setFieldValue('SubStatus', 'Approved Estimates');

                                          setPeerReviewer('');
                                        }}
                                      />
                                      &nbsp;No
                                    </FormGroup>
                                  </FormGroup>
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                            &nbsp;
                            {finalPswValues.FormStatus === 'submit' &&
                            formik.values.q11 === 'yes' ? (
                              <Row>
                                <Col md="3">
                                  <Label>Assign a Peer Reviewer</Label>
                                  &nbsp;
                                  <Icon.HelpCircle
                                    id="toolTip-peerReviewer"
                                    size={20}
                                    style={{ color: 'blue' }}
                                  />
                                  <TooltipItem target="toolTip-peerReviewer" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        0 to 150k quote from contractor - No peer review required
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        150k to 500k quote from contractor - Delivery Manager peer
                                        review required (Leo/Tony)
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        500k to 3mil - Head of IS peer review required (Peter G)
                                      </p>
                                    </div>
                                  </TooltipItem>
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className={`invalid-feedback ${
                                      formikRef.current.errors.peerReviewer ? '' : 'd-none'
                                    }`}
                                  >
                                    Required
                                  </div>
                                </Col>
                                <Col md="3">
                                  <ActiveDirectoryAutoSuggest
                                    disabled={
                                      (assignedTech !== currentLoggedInUser &&
                                        !TSA_ADMINS.includes(currentLoggedInUser)) ||
                                      readOnly
                                    }
                                    id="peerReviewer"
                                    changeCallBack={(value, mail) => {
                                      peerReviewerCallBack(value, mail, formik);
                                    }}
                                    defaultValue={peerReviewer}
                                  />
                                </Col>
                              </Row>
                            ) : (
                              ''
                            )}
                          </CardBody>
                        </Card>
                      ) : (
                        ''
                      )}
                      {composeBottomFormButtons(formik)}
                      <Row>
                        <Col xs="12" md="6">
                          <ProcessingModal ref={processingModalRef} />
                          <GenericMessageModal body={successModalBody} ref={successModalRef} />
                          <ErrorModal ref={errorModalRef} />
                          <ConfirmationModal
                            ref={rejectModalRef}
                            body={`Do you want to reject this Indicative Scope of Works for ${iswValues.ClientCompany}-${iswValues.MainContact} with Email ${iswValues.ClientEmail}`}
                            showCommentBox
                            confirmText="Confirm"
                            cancelText="Cancel"
                            formikProps={formik}
                            cancelCallBack={() => {
                              formik.setFieldValue('q4', 'yes');
                              rejectModalRef.current.toggleModal();
                            }}
                            submitCallBack={() => {
                              handleTechReject(formik);
                            }}
                          />
                          <ConfirmationModal
                            ref={peerRejectModalRef}
                            submitCallBack={() => {
                              handlePeerReject(formik);
                            }}
                            body="You are about to request revision. Please enter a reason"
                            showCommentBox
                            confirmText="Confirm"
                            cancelText="Cancel"
                            formikProps={formik}
                          />
                          <WarningModal
                            ref={nothingToDownloadModalRef}
                            body="Nothing to download"
                          />
                          <VeryLargeModal
                            ref={iswModalRef}
                            size="xl"
                            backdrop="static"
                            header="Please fill out and submit"
                            submitCallBack={() => {
                              iswHandleSubmit(formik);
                            }}
                            showFooterButtons
                          >
                            <IswForm
                              ref={iswFormRef}
                              childComponent
                              data={iswValues}
                              showContainer
                            />
                          </VeryLargeModal>
                          <VeryLargeModal
                            ref={pswModalRef}
                            size="xl"
                            backdrop="static"
                            header="Please fill out and submit"
                            submitCallBack={() => {
                              pswHandleSubmit('submit');
                            }}
                            draftSubmitCallBack={() => {
                              pswHandleSubmit('draft');
                            }}
                            showFooterButtons
                          >
                            <PswForm
                              id="preliminaryscopeofwork"
                              title={`Preliminary Technical Scope of works - ${formik.values.DocumentNumber}`}
                              ref={pswFormRef}
                              initialValues={pswValues}
                              fileUploadUrl={PSW_FILE_UPLOAD_URL}
                              fileDownloadUrl={PSW_FILE_DOWNLOAD_URL}
                            />
                          </VeryLargeModal>
                          <VeryLargeModal
                            ref={finalPswModalRef}
                            size="xl"
                            backdrop="static"
                            header="Please fill out and submit"
                            submitCallBack={() => {
                              finalPswHandleSubmit(formik, 'submit');
                            }}
                            draftSubmitCallBack={() => {
                              finalPswHandleSubmit(formik, 'draft');
                            }}
                            showFooterButtons
                          >
                            <FinalPswForm ref={finalPswFormRef} initialValues={finalPswValues} />
                          </VeryLargeModal>
                          <VeryLargeModal
                            ref={confirmedPswModalRef}
                            size="xl"
                            backdrop="static"
                            header="Please fill out and submit"
                            submitCallBack={() => {
                              confirmedPswHandleSubmit(formik);
                            }}
                          >
                            <PswForm
                              id="confirmedpreliminaryscopeofwork"
                              title={`Technical Scope of works - Contractor version - ${formik.values.DocumentNumber}`}
                              ref={confirmedPswFormRef}
                              initialValues={confirmedPswValues}
                              fileUploadUrl={CONFIRMED_PSW_UPLOAD_URL}
                              fileDownloadUrl={CONFIRMED_PSW_DOWNLOAD_URL}
                              disabled
                            />
                          </VeryLargeModal>
                          <RequiredFieldsWarningModal ref={requiredFieldsWarningModalRef} />
                          <SmallModal
                            ref={documentSelectModalRef}
                            size="xl"
                            backdrop="static"
                            header="Select a document to assign"
                          >
                            <Row className="mb-4">
                              <Col md="12">
                                <Col md="12">
                                  <FormGroup check style={{ textAlign: 'left' }}>
                                    <Field
                                      disabled={readOnly}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="siteInfoCheck"
                                      name="siteInfoCheck"
                                    />
                                    <Label check>Site Information Documentation</Label>
                                  </FormGroup>
                                  <FormGroup check style={{ textAlign: 'left' }}>
                                    <Field
                                      disabled={readOnly}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="pswCheck"
                                      name="pswCheck"
                                    />
                                    <Label check>Preliminary Scope of Works</Label>
                                  </FormGroup>
                                  <FormGroup check style={{ textAlign: 'left' }}>
                                    <Field
                                      disabled={readOnly}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="engineeringCheck"
                                      name="engineeringCheck"
                                    />
                                    <Label check>Engineering Design</Label>
                                  </FormGroup>
                                </Col>
                              </Col>
                            </Row>
                          </SmallModal>
                          <ConfirmationModal
                            ref={archiveModalRef}
                            submitCallBack={() => {
                              if (iswValues.Archived) {
                                handleArchiveClick(false);
                              } else {
                                handleArchiveClick(true);
                              }
                            }}
                            body={
                              iswValues.Archived
                                ? 'You are about remove the request from the archive. Please enter a reason'
                                : 'You are about to archive this request. Please enter a reason'
                            }
                            showCommentBox
                            confirmText="Confirm"
                            cancelText="Cancel"
                            formikProps={formik}
                          />
                        </Col>
                      </Row>
                    </FormikForm>
                  );
                }
              }
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TenderForm;
