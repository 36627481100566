import React, { forwardRef, useRef, useState, useCallback } from 'react';
import { Card, CardBody, CardTitle, Badge, Row, Col, FormGroup, Label } from 'reactstrap';
import * as Icon from 'react-feather';
import { Formik, Field } from 'formik';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
import PropTypes from 'prop-types';
import _ from 'lodash'; // Import Lodash
import TooltipItem from './Tooltip';

import {
  SmallModal,
  ErrorModal,
  RequiredFieldsWarningModal,
  ProcessingModal,
  ConfirmationModal,
} from '../modals/CustomModals';
import { putAsync, deleteAsync, getAsync, postAsync } from '../../functions/my-api';

import UppyFileUploader from '../../layouts/uppy/uppy';
import { generateRandomString } from '../../functions/common';

// eslint-disable-next-line no-unused-vars
const Addendum = forwardRef((props, ref) => {
  const styles = {
    cell: {
      border: '1px solid #DEE2E6',
      padding: '8px 12px',
      textAlign: 'left',
    },
    row: {
      border: '1px solid #DEE2E6',
      padding: '.25em',
    },
  };

  const tenderAddendumModalRef = useRef();
  const relatedDocumentsRef = useRef();
  const errorModalRef = useRef();
  const requiredFieldsWarningModalRef = useRef();
  const formikRef = useRef();
  const processingModalRef = useRef();
  const deleteAddendumConfirmationRef = useRef();

  const [fileUploaderDisabled, setFileUploaderDisabled] = useState(true);
  const [uppyRefreshKey, setUppyRefreshKey] = useState(generateRandomString());
  const [selectedAddendumId, setSelectedAddendumId] = useState();
  const [addendums, setAddendums] = useState(props?.addendums ? props?.addendums : []);
  const [selectedAddendum, setSelectedAddendum] = useState({});
  const [disabledFlag, setDisabledFlag] = useState(false);

  const currentLoggedInUser = JSON.parse(localStorage.JETChargeCurrentUser).name;

  const debounceDescriptionChange = useCallback(
    _.debounce(async (e) => {
      if (e.target.value) {
        setFileUploaderDisabled(false);
        setUppyRefreshKey(generateRandomString());
      } else {
        setFileUploaderDisabled(true);
        setUppyRefreshKey(generateRandomString());
      }
    }, 1000),
    [],
  );

  const handleDescriptionChange = (e) => {
    debounceDescriptionChange(e);
  };

  const validateForm = () => {
    const validationErrors = [];

    if (!document.getElementById('description').value) {
      formikRef.current.errors.description = 'Description is required';
      validationErrors.push({ description: formikRef.current.errors.description });
    } else {
      delete formikRef.current.errors.description;
    }

    return validationErrors;
  };

  const handleAddAddendum = async () => {
    const validationResult = validateForm();

    if (Object.keys(validationResult).length === 0) {
      const addendum = {
        photoFor: `${selectedAddendumId}`,
        description: document.getElementById('description').value,
        dateCreated: new Date(),
        createdBy: currentLoggedInUser,
        status: 'For Review',
      };

      tenderAddendumModalRef.current.toggleModal();
      processingModalRef.current.toggleModal();

      let response = {};
      if (Object.keys(selectedAddendum).length) {
        response = await putAsync(
          `${process.env.REACT_APP_TSA_URL}/tender/addendum/${props.parentId}`,
          addendum,
        );
      } else {
        response = await postAsync(
          `${process.env.REACT_APP_TSA_URL}/tender/addendum/${props.parentId}`,
          addendum,
        );
      }

      if (response.status === 200) {
        setAddendums(response.data.Data ? JSON.parse(response.data.Data)?.addendums : []);
      } else {
        errorModalRef.current.toggleModal();
      }

      processingModalRef.current.toggleModal();
    }
  };

  const handleDeleteAddendum = async () => {
    processingModalRef.current.toggleModal();

    const response = await deleteAsync(
      `${process.env.REACT_APP_TSA_URL}/tender/addendum/${props.parentId}/uid/${selectedAddendumId}`,
    );

    if (response.status === 200) {
      setAddendums(response.data.Data ? JSON.parse(response.data.Data)?.addendums : []);
    } else {
      errorModalRef.current.toggleModal();
    }

    processingModalRef.current.toggleModal();
  };

  const handleEditAddendum = async (iswId, addendumId) => {
    processingModalRef.current.toggleModal();

    const response = await getAsync(
      `${process.env.REACT_APP_TSA_URL}/tender/addendum/${iswId}/uid/${addendumId}`,
    );

    if (response.status === 200) {
      setSelectedAddendum(response.data);
      tenderAddendumModalRef.current.toggleModal();
    } else {
      errorModalRef.current.toggleModal();
    }

    processingModalRef.current.toggleModal();
  };

  const handleAcknowledgeAddendum = async (iswId, addendumId) => {
    processingModalRef.current.toggleModal();

    const response = await putAsync(
      `${process.env.REACT_APP_TSA_URL}/tender/addendum/acknowledge`,
      { iswId, addendumId },
    );

    if (response.status === 200) {
      setAddendums(JSON.parse(response?.data?.Data)?.addendums);
    } else {
      errorModalRef.current.toggleModal();
    }

    processingModalRef.current.toggleModal();
  };

  const renderTenderAddendums = () => {
    const result = [];

    if (!addendums || addendums?.length <= 0) {
      return result;
    }

    addendums?.forEach((element) => {
      result.push(
        <Tr key={`tenderaddendum_${element.Id}`} style={styles.row}>
          <Td style={styles.cell}>{element.createdBy}</Td>
          <Td style={styles.cell}>{element.dateCreated}</Td>
          <Td style={styles.cell}>{element.status}</Td>
          <Td style={styles.cell}>
            <span
              className="text-link"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                setDisabledFlag(true);
                setSelectedAddendumId(`${element.photoFor}`);
                setFileUploaderDisabled(true);
                handleEditAddendum(props.parentId, `${element.photoFor}`);
              }}
            >
              View
            </span>

            {props.disabled ? (
              ''
            ) : (
              <span
                className="text-link"
                style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px' }}
                onClick={() => {
                  setDisabledFlag(false);
                  setSelectedAddendumId(element.photoFor);
                  setFileUploaderDisabled(false);
                  handleEditAddendum(props.parentId, element.photoFor);
                }}
              >
                Edit
              </span>
            )}
            {props.disabled ? (
              ''
            ) : (
              <span
                className="text-link"
                style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px' }}
                onClick={() => {
                  deleteAddendumConfirmationRef.current.toggleModal();
                  setSelectedAddendumId(element.photoFor);
                }}
              >
                Delete
              </span>
            )}
            {props.acknowledge && element.status !== 'Acknowledged' ? (
              <span
                className="text-link"
                style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: '10px' }}
                onClick={() => {
                  handleAcknowledgeAddendum(props.parentId, element.photoFor);
                }}
              >
                Acknowledge
              </span>
            ) : (
              ''
            )}
          </Td>
        </Tr>,
      );
    });

    return result;
  };

  return (
    <Formik
      onSubmit={() => {
        // Do nothing. Everything is handled in the handleSubmit function
      }}
      innerRef={formikRef}
    >
      {
        //eslint-disable-next-line no-unused-vars
        (formik) => {
          return (
            <Card>
              <CardTitle
                tag="h5"
                className="border-bottom bg-white p-3 mb-0 text-black text-center card-rounded-top"
              >
                <span style={{ float: 'left' }}>Addendum</span>
                <Icon.HelpCircle
                  id="toolTip-addendum"
                  size={20}
                  style={{ color: 'blue', float: 'left', marginLeft: '10px' }}
                />
                <TooltipItem target="toolTip-addendum" placement="top">
                  <div>
                    <p style={{ textAlign: 'left' }}>
                      Sales person should add addendum if required
                    </p>
                  </div>
                </TooltipItem>
                {props.disabled ? (
                  ''
                ) : (
                  <Badge
                    color="primary"
                    className="ms-3"
                    pill
                    style={{ float: 'right', zIndex: 999, cursor: 'pointer' }}
                    onClick={() => {
                      tenderAddendumModalRef.current.toggleModal();
                      setSelectedAddendumId(`tenderaddendum_${generateRandomString()}`);
                      setSelectedAddendum({});
                      setFileUploaderDisabled(true);
                      setDisabledFlag(false);
                    }}
                  >
                    Add Addendum <Icon.FilePlus size={20} />
                  </Badge>
                )}
              </CardTitle>
              <CardBody className="card-rounded-bottom">
                <Table>
                  <Tbody>
                    <Tr style={styles.row}>
                      <Td style={styles.cell}>Added by</Td>
                      <Td style={styles.cell}>Date</Td>
                      <Td style={styles.cell}>Status</Td>
                      <Td style={styles.cell}>Actions</Td>
                    </Tr>
                    {renderTenderAddendums()}
                  </Tbody>
                </Table>
              </CardBody>
              <SmallModal
                ref={tenderAddendumModalRef}
                size="xl"
                backdrop="static"
                header="Add New Addendum"
                submitCallBack={() => {
                  handleAddAddendum();
                }}
                showFooterButtons={!disabledFlag}
                showSubmitButton={!disabledFlag}
                showCancelButton={!disabledFlag}
              >
                <Row>
                  <Col style={{ textAlign: 'left' }}>
                    <FormGroup>
                      <Label htmlFor="description">Description</Label>
                      <Field
                        id="description"
                        name="description"
                        className="form-control"
                        onChange={handleDescriptionChange}
                        defaultValue={selectedAddendum.description}
                        disabled={disabledFlag}
                      />
                      {formik.errors.description ? (
                        <div
                          style={{ display: 'block', textAlign: 'left' }}
                          className="invalid-feedback"
                        >
                          {formik.errors.description}
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ textAlign: 'left' }}>
                    <FormGroup>
                      <Label htmlFor={`${selectedAddendumId}`}>Related Documents:</Label>
                      <UppyFileUploader
                        id={`${selectedAddendumId}`}
                        ref={relatedDocumentsRef}
                        uploadUrl={`${props.fileUploadUrl}`}
                        downloadUrl={`${props.fileDownloadUrl}`}
                        existingFiles={selectedAddendum?.files ?? []}
                        key={uppyRefreshKey}
                        disabled={fileUploaderDisabled || disabledFlag}
                        description="Related Documents"
                        // fileChangedCallback={handleAddendumFileChange}
                      />
                      {formik.errors.relatedDocuments ? (
                        <div
                          style={{ display: 'block', textAlign: 'left' }}
                          className="invalid-feedback"
                        >
                          {formik.errors.relatedDocuments}
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </SmallModal>
              <ErrorModal ref={errorModalRef} />
              <RequiredFieldsWarningModal ref={requiredFieldsWarningModalRef} />
              <ProcessingModal ref={processingModalRef} />
              <ConfirmationModal
                ref={deleteAddendumConfirmationRef}
                body="Are you sure you want to remove this addendum?"
                confirmText="Delete"
                submitCallBack={handleDeleteAddendum}
              />
            </Card>
          );
        }
      }
    </Formik>
  );
});

Addendum.propTypes = {
  addendums: PropTypes.array,
  fileDownloadUrl: PropTypes.string,
  fileUploadUrl: PropTypes.string,
  parentId: PropTypes.string,
  disabled: PropTypes.bool,
  acknowledge: PropTypes.bool,
};

export default Addendum;
