import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react';
import { Card, CardBody, CardTitle, Row, Col, FormGroup, Label } from 'reactstrap';
import { Formik, Field, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Enumerable from 'linq';
import * as Icon from 'react-feather';

import htmlToPdf from '../../../functions/my-pdf';
import { ProcessingModal, SuccessModal, ErrorModal } from '../../modals/CustomModals';
import UppyFileUploader from '../../../layouts/uppy/uppy';
import TooltipItem from '../../widgets/Tooltip';

const PswForm = forwardRef((props, ref) => {
  const [initialValues] = useState({
    detailedScopeOfWorks: props.initialValues.Data.detailedScopeOfWorks ?? '',
    detailedScopeOfWorksCivil: props.initialValues.Data.detailedScopeOfWorksCivil ?? '',
    detailedScopeOfWorksOther: props.initialValues.Data.detailedScopeOfWorksOther ?? '',
    jcIssuedMaterials: props.initialValues.Data.jcIssuedMaterials,
    requiredDocuments: props.initialValues.Data.requiredDocuments ?? '',
    privateNotes: props.initialValues.Data.privateNotes ?? '',
    scopeDocumentsComments: props.initialValues.Data.scopeDocumentsComments ?? '',
    genSiteAssessmentRequirements: props.initialValues.Data.genSiteAssessmentRequirements ?? '',
    equipmentInstallationLocationDescription:
      props.initialValues.Data.equipmentInstallationLocationDescription ?? '',
    genLayoutCableDetailsDescription:
      props.initialValues.Data.genLayoutCableDetailsDescription ?? '',
    msbdbdnspDetailsDescription: props.initialValues.Data.msbdbdnspDetailsDescription ?? '',
    miscDetailsDescription: props.initialValues.Data.miscDetailsDescription ?? '',
    core: props.initialValues.Data.core ?? 'none',
    coreDetails: props.initialValues.Data.coreDetails ?? '',
    chargefox: props.initialValues.Data.chargefox ?? 'none',
    chargefoxDetails: props.initialValues.Data.chargefoxDetails ?? '',
    connectivity: props.initialValues.Data.connectivity ?? 'none',
    connectivityDetails: props.initialValues.Data.connectivityDetails ?? '',
    mechProtection: props.initialValues.Data.mechProtection ?? 'none',
    mechProtectionDetails: props.initialValues.Data.mechProtectionDetails ?? '',
  });

  const loggedInUser = JSON.parse(localStorage.getItem('JETChargeCurrentUser'))?.name;
  const [contractorMode] = useState(window.location.pathname.includes('/contractors/'));
  const [customerMode] = useState(window.location.pathname.includes('/customers/'));

  const processingModalRef = useRef();
  const successModalRef = useRef();
  const errorModalRef = useRef();
  const scopeDocumentsRef = useRef();
  const equipmentInstallationLocationRef = useRef();
  const msbdbdnspDetailsRef = useRef();
  const genLayoutCableDetailsRef = useRef();
  const miscDetailsRef = useRef();

  let globalFormik = {};

  const validationSchema = Yup.object().shape({
    detailedScopeOfWorks: Yup.string().required('Detailed Scope of Works is required'),
    // detailedScopeOfWorksCivil: Yup.string().required('Detailed Scope of Works - Civil is required'),
    // detailedScopeOfWorksOther: Yup.string().required('Detailed Scope of Works - Other is required'),
    jcIssuedMaterials: Yup.string().required('JC Issued Material to Contractor is required'),
    requiredDocuments: Yup.string().required('Required Documents is required'),
    privateNotes: Yup.string().required('Private Notes is required'),
    genSiteAssessmentRequirements: Yup.string().required(
      'General Site assessment requirements is required',
    ),
    equipmentInstallationLocationDescription: Yup.string().required(
      'Equipment Installation Location Description is required',
    ),
    msbdbdnspDetailsDescription: Yup.string().required(
      'MSB/DB/DNSP Details description is required',
    ),
    genLayoutCableDetailsDescription: Yup.string().required(
      'General layout and cabling details description is required',
    ),
    // miscDetailsDescription: Yup.string().required('Miscellaneous details description is required'),
    coreDetails: Yup.string().when('core', {
      is: (value) => value !== 'none', // Check if value is not 'yes'
      then: Yup.string().required('Core details is required'),
      otherwise: Yup.string(),
    }),
    // chargefox: Yup.string().required('Chargefox is required'),
    chargefoxDetails: Yup.string().when('chargefox', {
      is: (value) => value !== 'none', // Check if value is not 'yes'
      then: Yup.string().required('Chargefox details is required'),
      otherwise: Yup.string(),
    }),
    //connectivity: Yup.string().required('Required'),
    connectivityDetails: Yup.string().when('connectivity', {
      is: (value) => value !== 'none', // Check if value is not 'yes'
      then: Yup.string().required('Connectivity details is required'),
      otherwise: Yup.string(),
    }),
    //mechProtection: Yup.string().required('Required'),
    mechProtectionDetails: Yup.string().when('mechProtection', {
      is: (value) => value !== 'none', // Check if value is not 'yes'
      then: Yup.string().required('Mechanical Protectiion details is required'),
      otherwise: Yup.string(),
    }),
  });

  const validateForm = async () => {
    let response = {};
    response = await globalFormik.validateForm();

    // scopeDocuments: Yup.string().required('Scope Documents is required'),
    // equipmentInstallationLocation: Yup.string().required('Equipment Installation Location is required'),
    // msbdbdnspDetails: Yup.string().required('MSB/DB/DNSP Details is required'),
    // genLayoutCableDetails: Yup.string().required('General layout and cabling details is required'),
    // miscDetails: Yup.string().required('Miscellaneous details is required'),

    return response;
  };

  useImperativeHandle(ref, () => ({
    async getInputValues() {
      const payload = {
        ID: props.initialValues.ID,
        IswId: props.initialValues.IswId,
        Data: JSON.stringify(globalFormik.values),
        UpdatedBy: loggedInUser,
        PswPdf: await htmlToPdf(props.id),
      };

      return payload;
    },
    async validateForm() {
      const response = await validateForm();
      return response;
    },
  }));

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {
          // Do nothing. Everything is handled in the handleSubmit function
        }}
      >
        {
          //eslint-disable-next-line no-unused-vars
          (formik) => {
            globalFormik = formik;
            return (
              <>
                <FormikForm id={props.id ?? 'preliminaryscopeofwork'}>
                  <h2 style={{ textAlign: 'left' }}>
                    <strong>{props.title}</strong>
                  </h2>
                  {contractorMode || customerMode ? (
                    <Label
                      className="hidePrint"
                      style={{ display: 'block', textAlign: 'left' }}
                      color="muted"
                    >
                      Please confirm if the following information is correct and if not make any
                      changes to the scope as required
                    </Label>
                  ) : (
                    ''
                  )}
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h3"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>Installation Scope of Works</span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="detailedScopeOfWorks">
                                  Detailed Scope of Works
                                </Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-detailedScopeOfWorks"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem
                                    target="toolTip-detailedScopeOfWorks"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below scope of works written by our JET Charge team that
                                        we&apos;re expecting you to review and price. This scope has
                                        been written site unseen based on information provided by
                                        our client, we are anticipating there to be changes based on
                                        site conditions
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        During your site visit, please ammend or update the below
                                        scope based on site conditions (we have saved a copy of the
                                        original), ultimately we hope to present your ammended scope
                                        and any good ideas you have to our client as part of our
                                        proposal thus increasing the likelihood of being awarded the
                                        work.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem
                                    target="toolTip-detailedScopeOfWorks"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Put in here scope of works for the contractor, be as
                                        prescriptive and clear as you can, this is the scope of
                                        works that the contractor will quote on.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        You can either put all of scope of works in this area, or
                                        use a the below categorised text boxes as required to
                                        lay-out your scope.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="detailedScopeOfWorks"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                />
                                {formik.errors.detailedScopeOfWorks ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.detailedScopeOfWorks}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          {((contractorMode || customerMode) &&
                            formik.values.detailedScopeOfWorksCivil) ||
                          (!contractorMode && !customerMode) ? (
                            <Row>
                              <Col md="12" style={{ textAlign: 'left' }}>
                                <FormGroup>
                                  <Label htmlFor="detailedScopeOfWorksCivil">
                                    Detailed Scope of Works - Civil
                                  </Label>
                                  &nbsp;
                                  <Icon.HelpCircle
                                    id="toolTip-detailedScopeOfWorksCivil"
                                    size={20}
                                    style={{ color: 'blue' }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-detailedScopeOfWorksCivil"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          See below civil scope of works written by our JET Charge
                                          team that we&apos;re expecting you to review and include
                                          within your price. This scope has been written site unseen
                                          based on information provided by our client, we are
                                          anticipating there to be changes based on site conditions
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          During your site visit, please ammend or update the below
                                          civil scope based on site conditions (we have saved a copy
                                          of the original)
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-detailedScopeOfWorksCivil"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Optional textbox if civil works are required. If civil
                                          scope was included in above this box can be left blank and
                                          will not be visible to the contractor
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="detailedScopeOfWorksCivil"
                                    placeholder=""
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                  />
                                  {formik.errors.detailedScopeOfWorksCivil ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.detailedScopeOfWorksCivil}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}
                          {((contractorMode || customerMode) &&
                            formik.values.detailedScopeOfWorksOther) ||
                          (!contractorMode && !customerMode) ? (
                            <Row>
                              <Col md="12" style={{ textAlign: 'left' }}>
                                <FormGroup>
                                  <Label htmlFor="detailedScopeOfWorksOther">
                                    Detailed Scope of Works - Other
                                  </Label>
                                  &nbsp;
                                  <Icon.HelpCircle
                                    id="toolTip-detailedScopeOfWorksOther"
                                    size={20}
                                    style={{ color: 'blue' }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-detailedScopeOfWorksOther"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          See below additional scope of works items written by our
                                          JET Charge team that we&apos;re expecting you to review
                                          and include within your price. This scope has been written
                                          site unseen based on information provided by our client,
                                          we are anticipating there to be changes based on site
                                          conditions
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          During your site visit, please ammend or update the below
                                          scope items based on site conditions (we have saved a copy
                                          of the original)
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-detailedScopeOfWorksOther"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Optional textbox if other non-standard works are required.
                                          If these works are already included above, this box can be
                                          left blank and will not be visible to the contractor
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="detailedScopeOfWorksOther"
                                    placeholder=""
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                  />
                                  {formik.errors.detailedScopeOfWorksOther ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.detailedScopeOfWorksOther}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          ) : (
                            ''
                          )}
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="jcIssuedMaterials">
                                  JC Issued Material to Contractor
                                </Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-jcIssuedMaterials"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem target="toolTip-jcIssuedMaterials" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below a list of items provided free-issue by JET Charge
                                        to be reviewed and considered within your price, this
                                        equipment has been selected site-unseen, if there are any
                                        comments/clarifications/considerations we should be aware
                                        of, we appreciate you adding any relevant comments to the
                                        below.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        If no equipment is listed below, it is the expectation that
                                        you allow for all items within your price.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem target="toolTip-jcIssuedMaterials" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Put in here all equipment that will be procured by JET
                                        Charge and issued to the contractor (chargers, bollards,
                                        switchgear etc).
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        If no equipment is being issued by JET Charge, please write
                                        &ldquo;No Equipments issued by JET Charge&ldquo;
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="jcIssuedMaterials"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                />
                                {formik.errors.jcIssuedMaterials ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.jcIssuedMaterials}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="requiredDocuments">Required Documents</Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-requiredDocuments"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem target="toolTip-requiredDocuments" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        As part of your offer we will require the below documents.
                                        Please include for these, if there is any reason you&apos;ll
                                        be unable to provide the below, please add notes in the
                                        below section.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem target="toolTip-requiredDocuments" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Put in here information that you will require from the
                                        contractor at the end of the project that they should allow
                                        for within their quote:
                                      </p>
                                      <ul style={{ textAlign: 'left' }}>
                                        <li>COES</li>
                                        <li>ITRs</li>
                                        <li>As-builts or Red-line markups</li>
                                        <li>MSB drawings etc</li>
                                      </ul>
                                    </div>
                                  </TooltipItem>
                                )}
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="requiredDocuments"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                />
                                {formik.errors.requiredDocuments ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.requiredDocuments}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          {contractorMode || customerMode ? (
                            ''
                          ) : (
                            <Row>
                              <Col md="12" style={{ textAlign: 'left' }}>
                                <FormGroup>
                                  <Label htmlFor="privateNotes">Private Notes</Label>
                                  &nbsp;
                                  <Icon.HelpCircle
                                    id="toolTip-privateNotes"
                                    size={20}
                                    style={{ color: 'blue' }}
                                  />
                                  <TooltipItem target="toolTip-privateNotes" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Put in here any notes or information you would like saved on
                                        the TACT portal, but that you&apos;d like kept private (only
                                        accessible by staff at JET Charge and not external)
                                      </p>
                                    </div>
                                  </TooltipItem>
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="privateNotes"
                                    placeholder=""
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                  />
                                  {formik.errors.privateNotes ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.privateNotes}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h3"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>
                            Scope Documents&nbsp;
                            <Icon.HelpCircle
                              id="toolTip-scopeDocuments"
                              size={20}
                              style={{ color: 'blue' }}
                            />
                          </span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                {contractorMode || customerMode ? (
                                  <TooltipItem target="toolTip-scopeDocuments" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below scoping documents from JET Charge. Please consider
                                        the content in these documents when viewing the site and
                                        pricing the works.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        We have saved copies of the originals, please add documents
                                        or edit existing documents including any amendments or
                                        clarifications
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        Your edits and additions can include anything you consider
                                        pertinant to convey to either JET Charge or the client
                                        during pricing.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem target="toolTip-scopeDocuments" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Upload here any/all scoping documents to be issued to the
                                        contractor for their site visit. These are the documents for
                                        the contractor to price from. These documents can include
                                        any/all of the following:
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        - Documents provided by the client that have already been
                                        uploaded to TACT (e.g. tender package documents or client
                                        markups)
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        - Documents you&apos;ve produced (SLDs, Nearmap, markups,
                                        cable runs, annotated drawings etc)
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        Don&apos;t worry if you do not know all information before
                                        going to site, you will have opportunity to ammend/update
                                        these documents following a site visit and information
                                        gathered either by yourself or a contractor
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <UppyFileUploader
                                  id={`${props.id}_scopeDocuments`}
                                  uploadUrl={props.fileUploadUrl}
                                  downloadUrl={props.fileDownloadUrl}
                                  existingFiles={Enumerable.from(props.initialValues.Photos)
                                    .where((x) => x.PhotoFor === `${props.id}_scopeDocuments`)
                                    .toArray()}
                                  description="Scope Documents"
                                  ref={scopeDocumentsRef}
                                  disabled={props.disabled}
                                  //fileChangedCallback={props.formFilesChangedCallback}
                                />
                                {formik.errors.scopeDocuments ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.scopeDocuments}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h3"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>
                            Scope Documents Comments&nbsp;
                            <Icon.HelpCircle
                              id="toolTip-scopeDocumentsComments"
                              size={20}
                              style={{ color: 'blue' }}
                            />
                          </span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <TooltipItem
                                  target="toolTip-scopeDocumentsComments"
                                  placement="top"
                                >
                                  <div>
                                    <p style={{ textAlign: 'left' }}>
                                      Having reviewed scope documents, or added any of your own,
                                      please add any relevant comments to this area that should be
                                      considered during pricing
                                    </p>
                                    <p style={{ textAlign: 'left' }}>
                                      If you have useful tips or suggestions based on your site
                                      visit that would effect the scope, please include them in this
                                      area
                                    </p>
                                  </div>
                                </TooltipItem>
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="scopeDocumentsComments"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                />
                                {formik.errors.scopeDocumentsComments ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.scopeDocumentsComments}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h3"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>
                            Site Assessment Request and Supporting Information
                          </span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="genSiteAssessmentRequirements">
                                  General Site assessment requirements
                                </Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-genSiteAssessmentRequirements"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem
                                    target="toolTip-genSiteAssessmentRequirements"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below the site checks requested by JET Charge, please
                                        conduct these checks whilst on-site and add or inset
                                        comments below based on your findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem
                                    target="toolTip-genSiteAssessmentRequirements"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        In this section add any/all things you&apos;d like checked
                                        whilst on-site. This will be done either by the site
                                        assessor or a JC Network Partner.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        You can choose to include all site check requirements in
                                        this text box, or use the below sub-categories if preferred.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        To assist the team visiting site, we have included below
                                        sections for you to upload any supporting documentation and
                                        notes. The site team will be able to view these documents
                                        and notes whilst on-site, and edit/amend based on their
                                        findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <Field
                                  className="form-control"
                                  component="textarea"
                                  name="genSiteAssessmentRequirements"
                                  placeholder=""
                                  rows="3"
                                  style={{ resize: 'none' }}
                                  disabled={props.disabled}
                                />
                                {formik.errors.genSiteAssessmentRequirements ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.genSiteAssessmentRequirements}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="equipmentInstallationLocation">
                                  Equipment Installation Location
                                </Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-equipmentInstallationLocation"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem
                                    target="toolTip-equipmentInstallationLocation"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below photos/documents showing the proposed equipment
                                        installation location identified during our desktop audit.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        Please drag/drop/add/delete any relevant photos or documents
                                        that either:
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        Confirm suitability of the existing proposed location of
                                        equipment, or provide an alternative preferred location
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        This should be done for equipment such as, DB-EVs, bollards,
                                        concrete pads etc
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem
                                    target="toolTip-equipmentInstallationLocation"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Drag and drop any photos or files into this area that assist
                                        in identifying the preferred location for equipment
                                        installation such as chargers, DB-EVs, bollards, concrete
                                        pads etc.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        These documents will be used to assist the site
                                        assessor/contractor when on-site get their bearings.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        When on-site the contractor/site assessor will have the
                                        opportunity to delete, add or ammend any documents in this
                                        area based on their findings
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <UppyFileUploader
                                  id={`${props.id}_equipmentInstallationLocation`}
                                  uploadUrl={props.fileUploadUrl}
                                  downloadUrl={props.fileDownloadUrl}
                                  existingFiles={Enumerable.from(props.initialValues.Photos)
                                    .where(
                                      (x) =>
                                        x.PhotoFor === `${props.id}_equipmentInstallationLocation`,
                                    )
                                    .toArray()}
                                  description="Scope Documents"
                                  ref={equipmentInstallationLocationRef}
                                  disabled={props.disabled}
                                  //fileChangedCallback={props.formFilesChangedCallback}
                                />
                                {formik.errors.equipmentInstallationLocation ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.equipmentInstallationLocation}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <br />
                                <div style={{ position: 'relative' }}>
                                  <Icon.HelpCircle
                                    id="toolTip-equipmentInstallationLocationDescription"
                                    size={20}
                                    style={{
                                      color: 'blue',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                    }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-equipmentInstallationLocationDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Following your visit, and any photos/documents added
                                          above, please include any relevant comments here based on
                                          your findings
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-equipmentInstallationLocationDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents and assist the contractor/site assessor,
                                          leave blank if you&apos;d prefer.
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          When on-site the contractor/site assessor will have the
                                          opportunity to delete, add or ammend the notes based on
                                          their findings
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="equipmentInstallationLocationDescription"
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                    placeholder=""
                                  />
                                  {formik.errors.equipmentInstallationLocationDescription ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.equipmentInstallationLocationDescription}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="msbdbdnspDetails">MSB/DB/DNSP Details</Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-msbdbdnspDetails"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem target="toolTip-msbdbdnspDetails" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Please add any additional photos/documents of the relevant
                                        electrical infrastructure in this area. This can, but need
                                        not be limited to DB-EV, MSB, Transformers, Nameplates etc.
                                        We would like you to include information on all electrical
                                        infrastructure that should be considered as part of the
                                        works.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem target="toolTip-msbdbdnspDetails" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Drag and drop any photos or files into this area that assist
                                        in identifying the preferred wiring location for the
                                        charging equipment. This can include photos or drawing of
                                        the MSB and/or any upstream electrical infrastructure that
                                        should be considered.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        These documents will be used to assist the site
                                        assessor/contractor in assessing electrical feasibility of
                                        the proposed design and if upstream electrical
                                        infrastructure upgrades are required.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        When on-site the contractor/site assessor will have the
                                        opportunity to delete, add or ammend any documents in this
                                        area based on their findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <UppyFileUploader
                                  id={`${props.id}_msbdbdnspDetails`}
                                  uploadUrl={props.fileUploadUrl}
                                  downloadUrl={props.fileDownloadUrl}
                                  existingFiles={Enumerable.from(props.initialValues.Photos)
                                    .where((x) => x.PhotoFor === `${props.id}_msbdbdnspDetails`)
                                    .toArray()}
                                  description="MSB/DB/DNSP Details"
                                  ref={msbdbdnspDetailsRef}
                                  disabled={props.disabled}
                                  //fileChangedCallback={props.formFilesChangedCallback}
                                />
                                {formik.errors.msbdbdnspDetails ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.msbdbdnspDetails}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <br />
                                <div style={{ position: 'relative' }}>
                                  <Icon.HelpCircle
                                    id="toolTip-msbdbdnspDetailsDescription"
                                    size={20}
                                    style={{
                                      color: 'blue',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                    }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-msbdbdnspDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Please add any relevant notes to the above electrical
                                          infrastrcuture in this section that would assist in
                                          creating a proposal. Things you may consider are:
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          - Proposed wiring location and available board space
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          - Requirement for additional boards/sub-boards/breakers
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          - Any required modifications to existing infrastructure
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          - Site capacity restraints
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          - Any required upgrades to achieve compliance
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-msbdbdnspDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents and assist the contractor/site assessor,
                                          leave blank if you&apos;d prefer.
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          When on-site the contractor/site assessor will have the
                                          opportunity to delete, add or ammend the notes based on
                                          their findings.
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="msbdbdnspDetailsDescription"
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                    placeholder=""
                                  />
                                  {formik.errors.msbdbdnspDetailsDescription ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.msbdbdnspDetailsDescription}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="genLayoutCableDetails">
                                  General Layout and Cabling Details
                                </Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-genLayoutCableDetails"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem
                                    target="toolTip-genLayoutCableDetails"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Please add additional files/photos into this area that
                                        assist in confirming the proposed general layout of the
                                        installation and preferred cable runs including building
                                        works or civils that may be required.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        Any documents already provided were created &quot;site
                                        unseen&quot;, so please add/delete/ammend documents as
                                        required to capture preferred layout and cabling details.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem
                                    target="toolTip-genLayoutCableDetails"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Drag and drop any photos or files into this area that assist
                                        in identifying the general layout of the installation and
                                        preferred cable runs.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        These documents will be used to assist the site
                                        assessor/contractor when on-site get their bearings.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        When on-site the contractor/site assessor will have the
                                        opportunity to delete, add or ammend any documents in this
                                        area based on their findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <UppyFileUploader
                                  id={`${props.id}_genLayoutCableDetails`}
                                  uploadUrl={props.fileUploadUrl}
                                  downloadUrl={props.fileDownloadUrl}
                                  existingFiles={Enumerable.from(props.initialValues.Photos)
                                    .where(
                                      (x) => x.PhotoFor === `${props.id}_genLayoutCableDetails`,
                                    )
                                    .toArray()}
                                  description="General Layout and Cabling Details"
                                  ref={genLayoutCableDetailsRef}
                                  disabled={props.disabled}
                                  //fileChangedCallback={props.formFilesChangedCallback}
                                />
                                {formik.errors.genLayoutCableDetails ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.genLayoutCableDetails}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <br />
                                <div style={{ position: 'relative' }}>
                                  <Icon.HelpCircle
                                    id="toolTip-genLayoutCableDetailsDescription"
                                    size={20}
                                    style={{
                                      color: 'blue',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                    }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-genLayoutCableDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents including the reason for relocating or
                                          modifying the original proposed layout (if applicable)
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-genLayoutCableDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents and assist the contractor/site assessor,
                                          leave blank if you&apos;d prefer.
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          When on-site the contractor/site assessor will have the
                                          opportunity to delete, add or ammend the notes based on
                                          their findings.
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="genLayoutCableDetailsDescription"
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                    placeholder=""
                                  />
                                  {formik.errors.genLayoutCableDetailsDescription ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.genLayoutCableDetailsDescription}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                <Label htmlFor="miscDetails">Miscellaneous Details</Label>
                                &nbsp;
                                <Icon.HelpCircle
                                  id="toolTip-miscDetails"
                                  size={20}
                                  style={{ color: 'blue' }}
                                />
                                {contractorMode || customerMode ? (
                                  <TooltipItem target="toolTip-miscDetails" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Please add any additional miscellaneous photos or files into
                                        this area that should be considered in confirming our
                                        proposal.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem target="toolTip-miscDetails" placement="top">
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Drag and drop any miscellaneous photos or files into this
                                        area that can be used to assist the site assessor/contractor
                                        when on-site.
                                      </p>
                                      <p style={{ textAlign: 'left' }}>
                                        When on-site the contractor/site assessor will have the
                                        opportunity to delete, add or ammend any documents in this
                                        area based on their findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                                <UppyFileUploader
                                  id={`${props.id}_miscDetails`}
                                  uploadUrl={props.fileUploadUrl}
                                  downloadUrl={props.fileDownloadUrl}
                                  existingFiles={Enumerable.from(props.initialValues.Photos)
                                    .where((x) => x.PhotoFor === `${props.id}_miscDetails`)
                                    .toArray()}
                                  description="Miscellaneous Details"
                                  ref={miscDetailsRef}
                                  disabled={props.disabled}
                                  //fileChangedCallback={props.formFilesChangedCallback}
                                />
                                {formik.errors.miscDetails ? (
                                  <div
                                    style={{ display: 'block', textAlign: 'left' }}
                                    className="invalid-feedback"
                                  >
                                    {formik.errors.miscDetails}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <br />
                                <div style={{ position: 'relative' }}>
                                  <Icon.HelpCircle
                                    id="toolTip-miscDetailsDescription"
                                    size={20}
                                    style={{
                                      color: 'blue',
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                    }}
                                  />
                                  {contractorMode || customerMode ? (
                                    <TooltipItem
                                      target="toolTip-miscDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents and assist JET Charge in generating a
                                          proposal
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  ) : (
                                    <TooltipItem
                                      target="toolTip-miscDetailsDescription"
                                      placement="top"
                                    >
                                      <div>
                                        <p style={{ textAlign: 'left' }}>
                                          Add any notes or captions you&apos;d like to support the
                                          above documents and assist the contractor/site assessor,
                                          leave blank if you&apos;d prefer.
                                        </p>
                                        <p style={{ textAlign: 'left' }}>
                                          When on-site the contractor/site assessor will have the
                                          opportunity to delete, add or ammend the notes based on
                                          their findings.
                                        </p>
                                      </div>
                                    </TooltipItem>
                                  )}
                                  <Field
                                    className="form-control"
                                    component="textarea"
                                    name="miscDetailsDescription"
                                    rows="3"
                                    style={{ resize: 'none' }}
                                    disabled={props.disabled}
                                    placeholder=""
                                  />
                                  {formik.errors.miscDetailsDescription ? (
                                    <div
                                      style={{ display: 'block', textAlign: 'left' }}
                                      className="invalid-feedback"
                                    >
                                      {formik.errors.miscDetailsDescription}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardTitle
                          tag="h3"
                          className="border-bottom bg-white p-3 mb-0 text-black text-center"
                        >
                          <span style={{ float: 'left' }}>
                            Additional Inclusions&nbsp;
                            <Icon.HelpCircle
                              id="toolTip-additionalInclusions"
                              size={20}
                              style={{ color: 'blue' }}
                            />
                          </span>
                        </CardTitle>
                        <CardBody>
                          <Row>
                            <Col md="12" style={{ textAlign: 'left' }}>
                              <FormGroup>
                                {contractorMode || customerMode ? (
                                  <TooltipItem
                                    target="toolTip-additionalInclusions"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        See below list of items to be considered in your pricing.
                                        Please add any relevant comments to the text boxes on the
                                        right hand-side based on your findings.
                                      </p>
                                    </div>
                                  </TooltipItem>
                                ) : (
                                  <TooltipItem
                                    target="toolTip-additionalInclusions"
                                    placement="top"
                                  >
                                    <div>
                                      <p style={{ textAlign: 'left' }}>
                                        Select from the list of drop down options on the left side
                                        of below, if required add notes in the details tab on the
                                        right side
                                      </p>
                                    </div>
                                  </TooltipItem>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" style={{ textAlign: 'left' }}>
                              <Label htmlFor="core">CORE</Label> &nbsp;
                              <Field
                                name="core"
                                as="select"
                                className="form-select form-select"
                                style={{ margin: 0, display: 'inline' }}
                                disabled={props.disabled}
                              >
                                <option value="none">None</option>
                                <option value="basic">Basic</option>
                                <option value="mini">Mini</option>
                                <option value="max">Max</option>
                              </Field>
                              {formik.errors.core ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.core}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                            <Col md="6" style={{ textAlign: 'left' }}>
                              <Label htmlFor="coreDetails">Details</Label> &nbsp;
                              <Field
                                className="form-control"
                                component="textarea"
                                name="coreDetails"
                                placeholder=""
                                rows="3"
                                style={{ resize: 'none' }}
                                disabled={props.disabled}
                              />
                              {formik.errors.coreDetails ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.coreDetails}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="chargefox">Chargefox</Label> &nbsp;
                              <Field
                                name="chargefox"
                                as="select"
                                className="form-select form-select"
                                style={{ margin: 0, display: 'inline' }}
                                disabled={props.disabled}
                              >
                                <option value="none">None</option>
                                <option value="private">Private</option>
                                <option value="public">Public</option>
                              </Field>
                              {formik.errors.chargefox ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.chargefox}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="chargefoxDetails">Details</Label> &nbsp;
                              <Field
                                className="form-control"
                                component="textarea"
                                name="chargefoxDetails"
                                placeholder=""
                                rows="3"
                                style={{ resize: 'none' }}
                                disabled={props.disabled}
                              />
                              {formik.errors.chargefoxDetails ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.chargefoxDetails}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="connectivity">Connectivity</Label> &nbsp;
                              <Field
                                name="connectivity"
                                as="select"
                                className="form-select form-select"
                                style={{ margin: 0, display: 'inline' }}
                                disabled={props.disabled}
                              >
                                <option value="none">None</option>
                                <option value="4g">4g</option>
                                <option value="local">Local</option>
                                <option value="wired">Wired</option>
                              </Field>
                              {formik.errors.connectivity ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.connectivity}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="connectivityDetails">Details</Label> &nbsp;
                              <Field
                                className="form-control"
                                component="textarea"
                                name="connectivityDetails"
                                placeholder=""
                                rows="3"
                                style={{ resize: 'none' }}
                                disabled={props.disabled}
                              />
                              {formik.errors.connectivityDetails ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.connectivityDetails}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="mechProtection">Mechanical Protection</Label> &nbsp;
                              <Field
                                name="mechProtection"
                                as="select"
                                className="form-select form-select"
                                style={{ margin: 0, display: 'inline' }}
                                disabled={props.disabled}
                              >
                                <option value="none">None</option>
                                <option value="bollards">Bollards</option>
                                <option value="wheelstops">Wheelstops</option>
                              </Field>
                              {formik.errors.mechProtection ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.mechProtection}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                            <Col md="6" className="pt-3" style={{ textAlign: 'left' }}>
                              <Label htmlFor="mechProtectionDetails">Details</Label> &nbsp;
                              <Field
                                className="form-control"
                                component="textarea"
                                name="mechProtectionDetails"
                                placeholder=""
                                rows="3"
                                style={{ resize: 'none' }}
                                disabled={props.disabled}
                              />
                              {formik.errors.mechProtectionDetails ? (
                                <div
                                  style={{ display: 'block', textAlign: 'left' }}
                                  className="invalid-feedback"
                                >
                                  {formik.errors.mechProtectionDetails}
                                </div>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                          <hr />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <ProcessingModal ref={processingModalRef} />
                    </Col>
                    <Col xs="12" md="6">
                      <SuccessModal ref={successModalRef} />
                    </Col>
                    <Col xs="12" md="6">
                      <ErrorModal ref={errorModalRef} />
                    </Col>
                  </Row>
                </FormikForm>
              </>
            );
          }
        }
      </Formik>
    </div>
  );
});

PswForm.propTypes = {
  initialValues: PropTypes.object,
  id: PropTypes.string,
  fileUploadUrl: PropTypes.string,
  fileDownloadUrl: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  //formFilesChangedCallback: PropTypes.func,
};

export default PswForm;
