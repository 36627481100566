import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import PropTypes from 'prop-types';

function ActiveDirectoryAutoSuggest(props) {
  const { instance, accounts } = useMsal();
  const [value, setValue] = useState(props.defaultValue ?? '');
  const [suggestions, setSuggestions] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [suggestionSelected, setSuggestionSelected] = useState(false); // Track if a suggestion was selected

  useEffect(() => {
    if (accounts.length > 0) {
      const fetchToken = async () => {
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ['User.ReadBasic.All'],
            account: accounts[0],
          });
          setAccessToken(tokenResponse.accessToken);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup({
                scopes: ['User.ReadBasic.All'],
              })
              .then((response) => {
                setAccessToken(response.accessToken);
              })
              .catch((err) => {
                console.error('Error acquiring token interactively:', err);
              });
          } else {
            console.error('Error acquiring token', error);
          }
        }
      };

      fetchToken();
    }
  }, [accounts, instance]);

  const searchUsers = async (query, token) => {
    const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${query}')`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    return data.value;
  };

  const onSuggestionsFetchRequested = async ({ value: inputVal }) => {
    const result = await searchUsers(inputVal, accessToken);
    setSuggestions(result);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // This function is called when a suggestion is chosen
  const getSuggestionValue = (suggestion) => {
    // If a change callback exists, call it with the selected suggestion details
    if (props.changeCallBack) {
      props.changeCallBack(`${suggestion.givenName} ${suggestion.surname}`, suggestion.mail);
    }
    return suggestion.displayName;
  };

  const inputChange = (newValue) => {
    setValue(newValue);
  };

  const renderSuggestion = (suggestion) => {
    return <div style={{ textAlign: 'left' }}>{suggestion.displayName}</div>;
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    // Inline styles for absolute positioning
    const styles = {
      position: 'absolute', // Position the suggestions list absolutely
      zIndex: 1000, // Ensure it sits above other content
      backgroundColor: '#fff', // Background to make it stand out; adjust as needed
      width: '100%', // Match the width of the input box
      maxHeight: '300px', // Limit the height of the suggestions box
      overflowY: 'auto', // Add scroll to the container if content exceeds the max height
    };

    const groupedChildren = [];

    // Group children (individual suggestions) if needed
    React.Children.forEach(children, (child, index) => {
      const groupIndex = Math.floor(index / 3);
      if (!groupedChildren[groupIndex]) {
        groupedChildren[groupIndex] = [];
      }

      // Clone each child with a new unique key
      // eslint-disable-next-line react/no-array-index-key
      const childWithKey = React.cloneElement(child, { key: `suggestion-${index}` });
      groupedChildren[groupIndex].push(childWithKey);
    });

    return (
      <div {...containerProps} style={styles}>
        {groupedChildren.map((group, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Toast key={`toast-${index}`}>
            <ToastHeader>Please select one</ToastHeader>
            <ToastBody>{group}</ToastBody>
          </Toast>
        ))}
      </div>
    );
  };

  const inputProps = {
    placeholder: props.placeholder ? props.placeholder : 'Type a name',
    value,
    // When the input value changes, update state and mark that a suggestion hasn't been selected yet.
    onChange: (_, { newValue }) => {
      inputChange(newValue);
      setSuggestionSelected(false);
    },
    // onBlur: if no suggestion was selected, clear the input.
    onBlur: () => {
      if (!suggestionSelected) {
        setValue('');
      }
    },
    className: 'form-control mb-2',
    id: props.id,
    disabled: props.disabled,
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={inputProps}
      // When a suggestion is selected, set suggestionSelected to true.
      /* eslint-disable-nextline no-unused-vars */
      onSuggestionSelected={(_, { suggestion }) => {
        console.log(suggestion);
        setSuggestionSelected(true);
      }}
    />
  );
}

ActiveDirectoryAutoSuggest.propTypes = {
  id: PropTypes.string,
  changeCallBack: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default ActiveDirectoryAutoSuggest;
