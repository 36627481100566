import { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import heic2any from 'heic2any';
import Uppy, { debugLogger } from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Webcam from '@uppy/webcam';
// import XHRUpload from '@uppy/xhr-upload';
import Tus from '@uppy/tus';
import ImageEditor from '@uppy/image-editor';
import DropTarget from '@uppy/drop-target';
import Compressor from '@uppy/compressor';
import PropTypes from 'prop-types';
// import Enumerable from 'linq';
import Resizer from 'react-image-file-resizer';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { Label, Row, Col } from 'reactstrap';

import * as fileHelper from '../../functions/my-file';
import { getAsync, deleteAsync, generateAccessToken } from '../../functions/my-api';
import { ErrorModal } from '../../views/modals/CustomModals';
import { generateRandomString, isImage } from '../../functions/common';

const UppyFileUploader = forwardRef((props, ref) => {
  // const XHR_ENDPOINT = `${props.uploadUrl}?photoFor=${props.id}&description=${props.description}`;
  const TUS_ENDPOINT = `${props.uploadUrl}?photoFor=${props.id}&description=${props.description}`;

  const [actions, setActions] = useState([]);
  const [existingFiles, setExistingFiles] = useState(props.existingFiles ?? []);
  const [loadedFiles, setLoadedFiles] = useState(0);
  // const [loading, setLoading] = useState(false);

  const errorModalRef = useRef();
  const uppyDashboard = useRef();

  useImperativeHandle(ref, () => ({
    getExistingFiles() {
      return existingFiles;
    },
    isEmpty() {
      return loadedFiles === 0;
    },
  }));

  const setLoading = (e) => {
    if (e) {
      document.getElementById(`${props.id}_loaded`).style.display = 'none';
      document.getElementById(`${props.id}_loading`).style.display = 'block';
    } else {
      document.getElementById(`${props.id}_loaded`).style.display = 'block';
      document.getElementById(`${props.id}_loading`).style.display = 'none';
    }
  };

  const handleUppyFileDownload = async (id) => {
    const response = await getAsync(`${props.downloadUrl}/${id}`);

    // let base64String = '';
    // if (response.data?.Base64String) {
    //   base64String = response.data.Base64String;
    // } else {
    const binaryString = Array.from(response.data.FileContent.data)
      .map((byte) => String.fromCharCode(byte))
      .join('');
    const base64String = window.btoa(binaryString);
    //}

    fileHelper.DownloadFile({
      base64String,
      fileName: response.data.FileName,
      mimeType: response.data.MimeType,
    });
  };

  // const getContainerClass = (id) => {
  //   const width = getElementSize(id);
  //   switch (width) {
  //     case 'small': {
  //       return 'col-sm-5';
  //     }
  //     case 'medium': {
  //       return 'col-md-3';
  //     }
  //     case 'large': {
  //       return 'col-lg-2';
  //     }
  //     default: {
  //       return 'col-sm-5';
  //     }
  //   }
  // };

  const removeFile = async (file, deleteServerCopy) => {
    // Now remove the file using the fileId
    if (!file?.meta?.existingFile || deleteServerCopy) {
      uppyDashboard.current.removeFile(file.id);
    }

    if (file?.meta?.serverId && deleteServerCopy) {
      const response = await deleteAsync(`${props.downloadUrl}/${file.meta.serverId}`);
      if (response.status === 200) {
        if (props.fileChangedCallback) {
          props.fileChangedCallback(file, 'file-removed');
        }

        setLoadedFiles(uppyDashboard.current.getFiles().length);
        /* eslint-disable no-use-before-define */
        generateFileActions();
      } else {
        errorModalRef.current.toggleModal();
      }
    }
  };

  const generateFileActions = () => {
    const rows = [];
    const columns = [];

    uppyDashboard.current.getFiles().forEach((file) => {
      columns.push(
        <Col id={file.id} key={`${file.id}`} md="4">
          <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <Label className="mt-4 mb-0">{file.meta.name}</Label>
          </div>
          <div>
            <Label
              className="text-primary"
              style={{ paddingRight: '20px', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => {
                handleUppyFileDownload(file.meta.serverId);
              }}
            >
              Download
            </Label>
            {!props.disabled ? (
              <Label
                className="text-danger"
                style={{
                  paddingRight: '20px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  removeFile(file, true);
                }}
              >
                Remove
              </Label>
            ) : (
              ''
            )}
          </div>
        </Col>,
      );
    });

    rows.push(<Row key={`${props.id}_loadedfiles`}>{columns}</Row>);

    setActions(rows);
  };

  const loadExistingFilesToUppy = () => {
    const files = [];
    existingFiles.forEach((element) => {
      files.push({
        name: `${generateRandomString()}_${element.FileName}`,
        type: element.MimeType,
        data: {},
        source: 'URL', // optional, determines the source of the file, for example, Instagram.
        isRemote: true, // optional, determines whether the file is remote or not.
        meta: {
          serverId: element.ID,
          existingFile: true,
        },
      });
    });

    uppyDashboard.current.addFiles(files);

    setExistingFiles([]);
    generateFileActions();
  };

  const processFile = async (file) => {
    const tempFile = {
      name: file.name,
      type: file.type,
      data: file.data, // Use file.data directly
    };

    if (!file?.meta?.checked && isImage(file.name, file.type)) {
      // uppyDashboard.removeFile(`${file.id}_no_server_delete`);
      removeFile(file, false);
    }

    if (file.type === 'image/heic' || file.type === 'image/heif') {
      // Convert the file from HEIC/HEIF to JPEG
      heic2any({
        blob: file.data,
        toType: 'image/jpeg',
        quality: 0.8,
      })
        .then((conversionResult) => {
          // Extract the file name without extension
          const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
          // Create a new file object from the conversion result
          const newFile = new File([conversionResult], `${fileNameWithoutExtension}.jpeg`, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          // Add the new file to Uppy
          uppyDashboard.current.addFile({
            name: newFile.name,
            type: newFile.type,
            data: newFile,
            source: 'Local', // optional, determines the source of the file, for example, Instagram.
            isRemote: false, // optional, determines whether the file is remote or not.
          });
        })
        .catch((error) => {
          console.error('Error converting file:', error);
          errorModalRef.current.toggleModal('There was an error converting the file!');
        });
    }

    if (file.data.size > 0 && !file?.meta?.checked) {
      return new Promise((resolve) => {
        const image = new Image();
        image.src = URL.createObjectURL(tempFile.data);

        image.onload = () => {
          const maxWidth = 1700;
          const maxHeight = 1900;

          let margin = 1;
          let newWidth = image.width;
          let newHeight = image.height;

          if (
            image.width < maxWidth &&
            image.height < maxHeight &&
            image.width < maxHeight &&
            image.height < maxWidth
          ) {
            uppyDashboard.current.addFile({
              name: tempFile.name,
              type: tempFile.type,
              data: tempFile.data,
              meta: { checked: true }, // Add a meta property to flag the file
            });
            resolve();
            return;
          }

          while (
            !(
              newWidth < maxWidth &&
              newHeight < maxHeight &&
              newWidth < maxHeight &&
              newHeight < maxWidth
            )
          ) {
            margin -= 0.05; // decrease the page by 5% everytime it does not fit the maxWidth and maxHeight
            const targetWidth = maxWidth * margin;
            const targetHeight = maxHeight * margin;
            const aspectRatio = newWidth / newHeight;

            if (aspectRatio > 1) {
              newWidth = targetWidth;
              newHeight = targetWidth / aspectRatio;
              if (newHeight > targetHeight) {
                newHeight = targetHeight;
                newWidth = targetHeight * aspectRatio;
              }
            } else {
              newHeight = targetHeight;
              newWidth = targetHeight * aspectRatio;
              if (newWidth > targetWidth) {
                newWidth = targetWidth;
                newHeight = targetWidth / aspectRatio;
              }
            }
          }

          Resizer.imageFileResizer(
            tempFile.data,
            newWidth,
            newHeight,
            'JPEG',
            100,
            0,
            (resizedBlob) => {
              const resizedFile = new File([resizedBlob], tempFile.name, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });

              resizedFile.data = resizedFile;
              processFile(resizedFile, margin);

              resolve();
            },
            'blob',
          );
        };
      });
    }
    // If no processing needed, resolve immediately
    return Promise.resolve();
  };

  const createUppy = async () => {
    const token = await generateAccessToken();
    const uppyExists = document.getElementById(props.id)?.childNodes.length;

    if (!uppyExists && token) {
      uppyDashboard.current = new Uppy({
        logger: debugLogger,
        onBeforeFileAdded: () => {
          setLoading(true);
          return true;
        },
        autoProceed: false, // Automatically start uploading files
        individualCancellation: true, // Enable individual file cancellation
        onBeforeUpload: (files) => {
          // Add documentuploadtype to each file's metadata
          const updatedFiles = { ...files }; // Clone the files object
          Object.keys(updatedFiles).forEach((fileId) => {
            const file = updatedFiles[fileId];
            file.meta.documentuploadtype = props.id; // Set your context dynamically
            file.meta.originalurl = TUS_ENDPOINT;
          });
          return updatedFiles; // Return the updated files
        },
      })
        .use(Dashboard, {
          inline: true,
          target: `#${props.id}`,
          showProgressDetails: true,
          proudlyDisplayPoweredByUppy: true,
          width: props.width ?? '100%',
          height: props.height ?? '300px',
          singleFileFullScreen: true,
          disabled: props.disabled ?? false,
        })
        .use(Webcam, {
          target: Dashboard,
          showVideoSourceDropdown: false,
          showRecordingLength: false,
          modes: ['picture'],
        })
        .use(ImageEditor, { target: Dashboard })
        .use(DropTarget, {
          target: document.body,
        })
        .use(Compressor)
        // KEEPING THIS INSTEAD WE NEED A DOUBLE IMPLEMENTATION
        // .use(XHRUpload, {
        //   endpoint: XHR_ENDPOINT,
        //   bundle: true,
        //   formData: true,
        //   fieldName: 'my_files',
        //   headers: {
        //     // Add your Authorization header here
        //     Authorization: `Bearer ${token}`,
        //   },
        // });
        .use(Tus, {
          endpoint: TUS_ENDPOINT,
          // chunkSize: 1048576, // 1MB in bytes
          async onBeforeRequest(req) {
            // const token = await getAuthToken();
            // not being validated anymore because i dont have the time to do this
            req.setHeader('Authorization', `Bearer ${token}`);
          },
          retryDelays: [0, 1000, 3000, 5000, 10000, 60000],
          limit: 1,
        });

      window.uppy = uppyDashboard;

      /*eslint-disable-next-line no-unused-vars*/
      uppyDashboard.current.on('complete', (result) => {
        setLoading(false);
        setLoadedFiles(uppyDashboard.current.getFiles().length);
      });

      uppyDashboard.current.on('files-added', (files) => {
        // console.log(files);
        Promise.all(files.map((file) => processFile(file)))
          .then(() => {
            setLoading(false);
            uppyDashboard.current.upload();
            // console.log('All files processed');
          })
          .catch((error) => {
            console.error('Error processing files:', error);
          });
      });

      uppyDashboard.current.on('error', (error) => {
        console.error('Error with Uppy:', error);
        errorModalRef.current.toggleModal(
          'Something went wrong with the upload! Please refresh the browser and upload again',
        );
      });

      uppyDashboard.current.on('upload-success', async (file, response) => {
        // The `uploadURL` contains the location of the uploaded file on the server
        const uploadUrl = response.uploadURL;
        if (uploadUrl) {
          try {
            // Code that may throw an error
            // Make a HEAD request to fetch metadata (including custom headers)
            const res = await fetch(uploadUrl, {
              method: 'HEAD',
              headers: {
                'Tus-Resumable': '1.0.0', // Required by the Tus protocol
                //'Cache-Control': 'no-cache',
                //Accept: '*/*',
              },
            });

            const uniquePrefix = generateRandomString();
            const base64Id = res.headers.get('upload-metadata');
            file.meta.serverId = atob(base64Id.split(' ').pop());
            file.meta.name = `${uniquePrefix}_${file.meta.name}`;
            file.name = file.meta.name;

            // Maintain for debugging
            console.log(`Upload URL: ${res.url} - UploadId: ${file.meta.serverId}`);
            generateFileActions();

            if (props.uploadCompleteCallback) {
              response.uppyGroupId = props.id;
              props.uploadCompleteCallback(response);
            }

            // Commenting since I dont see where it is being used.
            // if (props.fileChangedCallback) {
            //   props.fileChangedCallback(response, 'complete');
            // }
          } catch (error) {
            // Handle the error
            console.error('error occurred:', error.message);
          }
        }
      });

      loadExistingFilesToUppy();

      uppyDashboard.current.getFiles().forEach((file) => {
        uppyDashboard.current.setFileState(file.id, {
          uploadURL: TUS_ENDPOINT,
          progress: { uploadComplete: true, uploadStarted: true, percentage: 100 },
        });
      });
    }
  };

  useEffect(() => {
    createUppy();
    return () => {
      if (uppyDashboard.current) {
        uppyDashboard.current.close();
      }
    };
  }, []);

  return (
    <>
      <div
        id={`${props.id}_loaded`}
        style={{
          backgroundColor: 'white',
          // display: loading ? 'none' : 'block'
          display: 'block',
        }}
        className="bg-white"
      ></div>
      <div
        id={props.id}
        className="bg-white"
        style={{ backgroundColor: 'white', display: 'block' }}
      ></div>
      {actions}
      <div
        id={`${props.id}_loading`}
        className="bg-white"
        style={{
          backgroundColor: 'white',
          // display: loading ? 'block' : 'none',
          display: 'none',
          textAlign: 'center',
        }}
      >
        <h2>Generating Preview. Please wait.</h2>
      </div>
      <ErrorModal ref={errorModalRef} />
    </>
  );
});

UppyFileUploader.propTypes = {
  id: PropTypes.string,
  // name: PropTypes.string,
  uploadUrl: PropTypes.string,
  existingFiles: PropTypes.array,
  downloadUrl: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  uploadCompleteCallback: PropTypes.func,
  disabled: PropTypes.bool,
  fileChangedCallback: PropTypes.func,
};

export default UppyFileUploader;
